export function groupBy<T>(array: T[], keys: (keyof T)[]) {
  const groups = array.reduce(
    (r: { [key: string]: Partial<T> & { rows: T[] } }, row) => {
      const groupKey = keys.reduce((rk, key) => rk + row[key], "");
      r[groupKey] = {
        ...(keys.reduce(
          (rv, key) => ({ ...rv, [key]: row[key] }),
          {}
        ) as Partial<T>),
        rows: [...(r[groupKey]?.rows || []), row],
      };
      return r;
    },
    {}
  );

  return Object.values(groups);
}

export function arrayRandomElement<T>(array: T[]) {
  const rndIndex = Math.round(Math.random() * array.length);
  return array[rndIndex];
}
