import { FillPaintProps } from "maplibre-gl";
import { Base } from "types/mapStyle";

export const formatDate = (date: string) => {
  const d = new Date(date);
  const year = d.toLocaleString("en-US", { year: "numeric" });
  const month = d.toLocaleString("en-US", { month: "short" });
  const day = d.toLocaleString("en-US", { day: "numeric" });

  return `${month} ${day}, ${year}`;
};

export const formatPaint = (base: Base | undefined) =>
  ({
    "fill-opacity": [
      "case",
      [
        "all",
        ["has", "system-style"],
        ["has", "opacity", ["get", "system-style"]],
      ],
      ["get", "opacity", ["get", "system-style"]],
      base?.properties["fill-opacity"],
    ],
    "fill-color": [
      "case",
      [
        "all",
        ["has", "system-style"],
        ["has", "color", ["get", "system-style"]],
      ],
      ["get", "color", ["get", "system-style"]],
      base?.properties.fill,
    ],
    "fill-outline-color": [
      "case",
      [
        "all",
        ["has", "system-style"],
        ["has", "color", ["get", "system-style"]],
      ],
      ["get", "color", ["get", "system-style"]],
      base?.properties.stroke,
    ],
  } as unknown as FillPaintProps);

export const formatSelectedPaint = (base: Base | undefined) =>
  ({
    "fill-opacity": [
      "case",
      [
        "all",
        ["has", "system-style"],
        ["has", "opacity", ["get", "system-style"]],
      ],
      ["get", "opacity", ["get", "system-style"]],
      base?.selectedProperties["fill-opacity"],
    ],
    "fill-color": [
      "case",
      [
        "all",
        ["has", "system-style"],
        ["has", "color", ["get", "system-style"]],
      ],
      ["get", "color", ["get", "system-style"]],
      base?.selectedProperties.fill,
    ],
    "fill-outline-color": [
      "case",
      [
        "all",
        ["has", "system-style"],
        ["has", "color", ["get", "system-style"]],
      ],
      ["get", "color", ["get", "system-style"]],
      base?.selectedProperties.stroke,
    ],
  } as unknown as FillPaintProps);

export const formatHSLColor = (color: number) => `hsl(${color}, 100%, 50%)`;

export const removeColorOpacity = (systemStyle: {
  color: string;
  text?: string;
  opacity: number;
}) => {
  const { color, opacity, ...rest } = systemStyle;
  return { ...rest };
}
