import {
  analyticsErrorLog,
  analyticsRegister,
  analyticsRegisterFailed,
} from "utils";
import { useAPI } from "hooks/api/useAPI";
import { postRegister } from "hooks/api/utils";

export function useRegister() {
  return useAPI(postRegister, {
    onSuccess: () => {
      analyticsRegister();
    },
    onFail: (errors) => {
      analyticsRegisterFailed(errors);
    },
    onError: () => {
      analyticsErrorLog("useRegister");
    },
  });
}
