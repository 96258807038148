import { useAuth } from "auth";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getUser, patchUser } from "hooks/api/utils";

export function useUser(id: string) {
  const { token } = useAuth();

  const queryClient = useQueryClient();

  const user = useQuery(["user", id], () => getUser(token || "", id));

  const updateUser = useMutation(
    (updatedUser: FormData) => {
      return patchUser(token || "", id, updatedUser);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries(["user", id]);
      },
    }
  );

  return {
    isLoading: user.isLoading,
    data: user?.data?.data,
    updateUser: updateUser.mutate,
  };
}
