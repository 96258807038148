import * as React from "react";
import {
  APIEndpoint,
  APIResponse,
  FieldsErrors,
  UseAPIOptions,
} from "hooks/api/types";

export function useAPI<T>(
  execute: (...args: any) => Promise<Response | APIResponse<T>>,
  options?: UseAPIOptions<T>
): APIEndpoint<T> {
  const [data, setData] = React.useState<T | undefined>(options?.initialState);

  const [errors, setErrors] = React.useState<FieldsErrors>();

  const cleanErrors = React.useCallback(() => {
    setErrors({});
  }, []);

  const process = React.useCallback(
    (...args: any) => {
      options?.onFetch?.();
      return execute(...args).then((response) => {
        try {
          if (response.errors) {
            setErrors(response.errors);
            options?.onFail?.(response.errors);
          } else if (response.error) {
            setErrors({ error: [response.error.message] });
            options?.onFail?.({ error: [response.error.message] });
          } else if (response.data) {
            setData(response.data);
            setErrors({});
            options?.onSuccess?.(response.data);
            return response.data;
          }
          return false;
        } catch (err) {
          options?.onError?.();
          return false;
        }
      });
    },
    [execute, options]
  );

  const clearData = React.useCallback(() => {
    setData(undefined);
  }, []);

  return {
    data,
    process,
    errors,
    cleanErrors,
    clearData,
  };
}
