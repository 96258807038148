import * as React from "react";
import { useAuth } from "auth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteUserGeojson,
  getUserGeojson,
  postUserGeojson,
  putUserGeojson,
} from "hooks/api/utils";
import { useAPI } from "hooks/api/useAPI";
import { UserGeojson } from "hooks/api/types";

export function useUserGeojson(id: string, date?: string, name?: string) {
  const { token } = useAuth();
  const queryClient = useQueryClient();
  const [page, setPage] = React.useState(0);

  const userGeojson = useQuery(["user-geojson", id, page, date, name], () =>
    getUserGeojson(token || "", id, page, 10, date, name)
  );

  const updateUserGeojson = useMutation(
    (updatedUserGeojsons: Pick<UserGeojson, "name" | "geojsons">) => {
      return putUserGeojson(token || "", id, updatedUserGeojsons);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user-geojson");
        queryClient.invalidateQueries(["user-geojson", id]);
      },
    }
  );

  const createUserGeojson = useAPI(
    (data) => postUserGeojson(token || "", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user-geojson");
      },
    }
  );

  const removeUserGeojson = useMutation(
    () => deleteUserGeojson(token || "", id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user-geojson");
        queryClient.invalidateQueries(["user-geojson", id]);
      },
    }
  );

  return {
    isLoading: userGeojson.isLoading,
    data: userGeojson?.data?.data,
    updateUserGeojson: updateUserGeojson.mutate,
    createUserGeojson,
    removeUserGeojson: removeUserGeojson.mutate,
    onChangePage: setPage,
    pagination: userGeojson?.data?.pagination,
  };
}
