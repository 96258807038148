import { useAuth } from "auth";
import { useQuery } from "react-query";
import { getMessage } from "hooks/api/utils";

export function useMessage(id: string) {
  const { token } = useAuth();

  const messages = useQuery(["message", id], () => getMessage(token || "", id));

  return {
    isLoading: messages.isLoading,
    data: messages?.data?.data,
  };
}
