import { LayerSpecification } from "maplibre-gl";

export const CANNONICAL_URL = "https://restgis.com";
export const API_URL = "/";
export const DISABLE_ANALYTICS = false;
export const TWITTER_URL = "https://twitter.com/Leniolabs_";
export const INSTAGRAM_URL = "https://www.instagram.com/leniolabs_/";

export const RASTER_BASE_URL =
  "https://basemaps.cartocdn.com/{style}/{z}/{x}/{y}@2x.png";

export const VECTOR_BASE_URL =
  "https://j75bhh02k0.execute-api.us-east-1.amazonaws.com/restgis-tiles/{z}/{x}/{y}";

const DARK_PROPERTIES = {
  properties: {
    "fill-opacity": 1,
    fill: "transparent",
    stroke: "#15CEDA",
  },
  selectedProperties: {
    stroke: "#45F9E0",
    fill: "transparent",
    "fill-opacity": 1,
    scaleIcon: "square_contour",
    rotateIcon: "circle_contour",
  },
};

const LIGHT_PROPERTIES = {
  properties: {
    "fill-opacity": 0.2,
    fill: "#000000",
    stroke: "#15CEDA",
  },
  selectedProperties: {
    stroke: "#999999",
    fill: "#000000",
    "fill-opacity": 0.2,
    scaleIcon: "square_contour_gray",
    rotateIcon: "circle_contour_gray",
  },
};

export const MAP_STYLES_LIST = [
  {
    name: "spotify_dark",
    ...DARK_PROPERTIES,
  },
  {
    name: "light",
    ...LIGHT_PROPERTIES,
  },
  {
    name: "positron",
    ...LIGHT_PROPERTIES,
  },
];

const LIGHT_LAYERS: Array<LayerSpecification> = [
  {
    id: "background",
    type: "background",
    paint: { "background-color": "hsl(47, 26%, 88%)" },
  },
  {
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["in", "class", "residential", "suburb", "neighbourhood"],
    ],
    id: "landuse-residential",
    paint: { "fill-color": "hsl(47, 13%, 86%)", "fill-opacity": 0.7 },
    source: "openmaptiles",
    "source-layer": "landuse",
    type: "fill",
  },
  {
    filter: ["==", "class", "grass"],
    id: "landcover_grass",
    paint: { "fill-color": "hsl(82, 46%, 72%)", "fill-opacity": 0.45 },
    source: "openmaptiles",
    "source-layer": "landcover",
    type: "fill",
  },
  {
    filter: ["==", "class", "wood"],
    id: "landcover_wood",
    paint: {
      "fill-color": "hsl(82, 46%, 72%)",
      "fill-opacity": {
        base: 1,
        stops: [
          [8, 0.6],
          [22, 1],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "landcover",
    type: "fill",
  },
  {
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "intermittent", 1]],
    id: "water",
    paint: { "fill-color": "hsl(205, 56%, 73%)" },
    source: "openmaptiles",
    "source-layer": "water",
    type: "fill",
  },
  {
    filter: ["all", ["==", "$type", "Polygon"], ["==", "intermittent", 1]],
    id: "water_intermittent",
    paint: { "fill-color": "hsl(205, 56%, 73%)", "fill-opacity": 0.7 },
    source: "openmaptiles",
    "source-layer": "water",
    type: "fill",
  },
  {
    filter: ["==", "subclass", "ice_shelf"],
    id: "landcover-ice-shelf",
    paint: { "fill-color": "hsl(47, 26%, 88%)", "fill-opacity": 0.8 },
    source: "openmaptiles",
    "source-layer": "landcover",
    type: "fill",
  },
  {
    filter: ["==", "subclass", "glacier"],
    id: "landcover-glacier",
    paint: {
      "fill-color": "hsl(47, 22%, 94%)",
      "fill-opacity": {
        base: 1,
        stops: [
          [0, 1],
          [8, 0.5],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "landcover",
    type: "fill",
  },
  {
    filter: ["all", ["in", "class", "sand"]],
    id: "landcover_sand",
    metadata: {},
    paint: {
      "fill-antialias": false,
      "fill-color": "rgba(232, 214, 38, 1)",
      "fill-opacity": 0.3,
    },
    source: "openmaptiles",
    "source-layer": "landcover",
    type: "fill",
  },
  {
    filter: ["==", "class", "agriculture"],
    id: "landuse",
    paint: { "fill-color": "#eae0d0" },
    source: "openmaptiles",
    "source-layer": "landuse",
    type: "fill",
  },
  {
    filter: ["==", "class", "national_park"],
    id: "landuse_overlay_national_park",
    paint: {
      "fill-color": "#E1EBB0",
      "fill-opacity": {
        base: 1,
        stops: [
          [5, 0],
          [9, 0.75],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "landcover",
    type: "fill",
  },
  {
    filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "tunnel"]],
    id: "waterway-tunnel",
    paint: {
      "line-color": "hsl(205, 56%, 73%)",
      "line-dasharray": [3, 3],
      "line-gap-width": {
        stops: [
          [12, 0],
          [20, 6],
        ],
      },
      "line-opacity": 1,
      "line-width": {
        base: 1.4,
        stops: [
          [8, 1],
          [20, 2],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "waterway",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["!in", "brunnel", "tunnel", "bridge"],
      ["!=", "intermittent", 1],
    ],
    id: "waterway",
    paint: {
      "line-color": "hsl(205, 56%, 73%)",
      "line-opacity": 1,
      "line-width": {
        base: 1.4,
        stops: [
          [8, 1],
          [20, 8],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "waterway",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["!in", "brunnel", "tunnel", "bridge"],
      ["==", "intermittent", 1],
    ],
    id: "waterway_intermittent",
    paint: {
      "line-color": "hsl(205, 56%, 73%)",
      "line-opacity": 1,
      "line-width": {
        base: 1.4,
        stops: [
          [8, 1],
          [20, 8],
        ],
      },
      "line-dasharray": [2, 1],
    },
    source: "openmaptiles",
    "source-layer": "waterway",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "tunnel"],
      ["==", "class", "transit"],
    ],
    id: "tunnel_railway_transit",
    layout: { "line-cap": "butt", "line-join": "miter" },
    minzoom: 0,
    paint: {
      "line-color": "hsl(34, 12%, 66%)",
      "line-dasharray": [3, 3],
      "line-opacity": {
        base: 1,
        stops: [
          [11, 0],
          [16, 1],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    id: "building",
    paint: {
      "fill-antialias": true,
      "fill-color": "rgba(222, 211, 190, 1)",
      "fill-opacity": {
        base: 1,
        stops: [
          [13, 0],
          [15, 1],
        ],
      },
      "fill-outline-color": {
        stops: [
          [15, "rgba(212, 177, 146, 0)"],
          [16, "rgba(212, 177, 146, 0.5)"],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "building",
    type: "fill",
  },
  {
    filter: ["==", "$type", "Point"],
    id: "housenumber",
    layout: {
      "text-field": "{housenumber}",
      "text-font": ["Open Sans Regular"],
      "text-size": 10,
    },
    minzoom: 14,
    paint: { "text-color": "rgba(212, 177, 146, 1)" },
    source: "openmaptiles",
    "source-layer": "housenumber",
    type: "symbol",
  },
  {
    id: "road_area_pier",
    type: "fill",
    metadata: {},
    source: "openmaptiles",
    "source-layer": "transportation",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "class", "pier"]],
    paint: { "fill-color": "hsl(47, 26%, 88%)", "fill-antialias": true },
  },
  {
    id: "road_pier",
    type: "line",
    metadata: {},
    source: "openmaptiles",
    "source-layer": "transportation",
    filter: ["all", ["==", "$type", "LineString"], ["in", "class", "pier"]],
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "hsl(47, 26%, 88%)",
      "line-width": {
        base: 1.2,
        stops: [
          [15, 1],
          [17, 4],
        ],
      },
    },
  },
  {
    filter: ["all", ["==", "$type", "Polygon"], ["in", "brunnel", "bridge"]],
    id: "road_bridge_area",
    layout: {},
    paint: { "fill-color": "hsl(47, 26%, 88%)", "fill-opacity": 0.5 },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "fill",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["in", "class", "path", "track"],
    ],
    id: "road_path",
    layout: { "line-cap": "square", "line-join": "bevel" },
    paint: {
      "line-color": "hsl(0, 0%, 97%)",
      "line-dasharray": [1, 1],
      "line-width": {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 10],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["in", "class", "minor", "service"],
    ],
    id: "road_minor",
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "hsl(0, 0%, 97%)",
      "line-width": {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
    minzoom: 13,
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "tunnel"],
      ["==", "class", "minor_road"],
    ],
    id: "tunnel_minor",
    layout: { "line-cap": "butt", "line-join": "miter" },
    paint: {
      "line-color": "#efefef",
      "line-dasharray": [0.36, 0.18],
      "line-width": {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "tunnel"],
      ["in", "class", "primary", "secondary", "tertiary", "trunk"],
    ],
    id: "tunnel_major",
    layout: { "line-cap": "butt", "line-join": "miter" },
    paint: {
      "line-color": "#fff",
      "line-dasharray": [0.28, 0.14],
      "line-width": {
        base: 1.4,
        stops: [
          [6, 0.5],
          [20, 30],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["in", "class", "runway", "taxiway"],
    ],
    id: "aeroway-area",
    metadata: { "mapbox:group": "1444849345966.4436" },
    minzoom: 4,
    paint: {
      "fill-color": "rgba(255, 255, 255, 1)",
      "fill-opacity": {
        base: 1,
        stops: [
          [13, 0],
          [14, 1],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "aeroway",
    type: "fill",
  },
  {
    filter: ["all", ["in", "class", "taxiway"], ["==", "$type", "LineString"]],
    id: "aeroway-taxiway",
    layout: { "line-cap": "round", "line-join": "round" },
    metadata: { "mapbox:group": "1444849345966.4436" },
    minzoom: 12,
    paint: {
      "line-color": "rgba(255, 255, 255, 1)",
      "line-opacity": 1,
      "line-width": {
        base: 1.5,
        stops: [
          [12, 1],
          [17, 10],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "aeroway",
    type: "line",
  },
  {
    filter: ["all", ["in", "class", "runway"], ["==", "$type", "LineString"]],
    id: "aeroway-runway",
    layout: { "line-cap": "round", "line-join": "round" },
    metadata: { "mapbox:group": "1444849345966.4436" },
    minzoom: 4,
    paint: {
      "line-color": "rgba(255, 255, 255, 1)",
      "line-opacity": 1,
      "line-width": {
        base: 1.5,
        stops: [
          [11, 4],
          [17, 50],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "aeroway",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["in", "class", "trunk", "primary"],
    ],
    id: "road_trunk_primary",
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "#fff",
      "line-width": {
        base: 1.4,
        stops: [
          [6, 0.5],
          [20, 30],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["in", "class", "secondary", "tertiary"],
    ],
    id: "road_secondary_tertiary",
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "#fff",
      "line-width": {
        base: 1.4,
        stops: [
          [6, 0.5],
          [20, 20],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: ["all", ["==", "$type", "LineString"], ["==", "class", "motorway"]],
    id: "road_major_motorway",
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "hsl(0, 0%, 100%)",
      "line-offset": 0,
      "line-width": {
        base: 1.4,
        stops: [
          [8, 1],
          [16, 10],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: ["all", ["==", "class", "transit"], ["!=", "brunnel", "tunnel"]],
    id: "railway-transit",
    paint: {
      "line-color": "hsl(34, 12%, 66%)",
      "line-opacity": {
        base: 1,
        stops: [
          [11, 0],
          [16, 1],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: ["==", "class", "rail"],
    id: "railway",
    paint: {
      "line-color": "hsl(34, 12%, 66%)",
      "line-opacity": {
        base: 1,
        stops: [
          [11, 0],
          [16, 1],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"]],
    id: "waterway-bridge-case",
    layout: { "line-cap": "butt", "line-join": "miter" },
    paint: {
      "line-color": "#bbbbbb",
      "line-gap-width": {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
      "line-width": {
        base: 1.6,
        stops: [
          [12, 0.5],
          [20, 10],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "waterway",
    type: "line",
  },
  {
    filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"]],
    id: "waterway-bridge",
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "hsl(205, 56%, 73%)",
      "line-width": {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "waterway",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "bridge"],
      ["==", "class", "minor_road"],
    ],
    id: "bridge_minor case",
    layout: { "line-cap": "butt", "line-join": "miter" },
    paint: {
      "line-color": "#dedede",
      "line-gap-width": {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
      "line-width": {
        base: 1.6,
        stops: [
          [12, 0.5],
          [20, 10],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "bridge"],
      ["in", "class", "primary", "secondary", "tertiary", "trunk"],
    ],
    id: "bridge_major case",
    layout: { "line-cap": "butt", "line-join": "miter" },
    paint: {
      "line-color": "#dedede",
      "line-gap-width": {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
      "line-width": {
        base: 1.6,
        stops: [
          [12, 0.5],
          [20, 10],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "bridge"],
      ["==", "class", "minor_road"],
    ],
    id: "bridge_minor",
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "#efefef",
      "line-width": {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "bridge"],
      ["in", "class", "primary", "secondary", "tertiary", "trunk"],
    ],
    id: "bridge_major",
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "#fff",
      "line-width": {
        base: 1.4,
        stops: [
          [6, 0.5],
          [20, 30],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "transportation",
    type: "line",
  },
  {
    filter: ["in", "admin_level", 4, 6, 8],
    id: "admin_sub",
    paint: { "line-color": "hsla(0, 0%, 60%, 0.5)", "line-dasharray": [2, 1] },
    source: "openmaptiles",
    "source-layer": "boundary",
    type: "line",
  },
  {
    filter: ["all", ["<=", "admin_level", 2], ["==", "$type", "LineString"]],
    id: "admin_country",
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "hsl(0, 0%, 60%)",
      "line-width": {
        base: 1.3,
        stops: [
          [3, 0.5],
          [22, 15],
        ],
      },
    },
    source: "openmaptiles",
    "source-layer": "boundary",
    type: "line",
  },
  {
    filter: ["all", ["==", "$type", "Point"], ["==", "rank", 1]],
    id: "poi_label",
    layout: {
      "icon-size": 1,
      "text-anchor": "top",
      "text-field": "{name}",
      "text-font": ["Open Sans Regular"],
      "text-max-width": 8,
      "text-offset": [0, 0.5],
      "text-size": 11,
    },
    minzoom: 14,
    paint: {
      "text-color": "#666",
      "text-halo-blur": 1,
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-halo-width": 1,
    },
    source: "openmaptiles",
    "source-layer": "poi",
    type: "symbol",
  },
  {
    filter: ["all", ["has", "iata"]],
    id: "airport-label",
    layout: {
      "icon-size": 1,
      "text-anchor": "top",
      "text-field": "{name}",
      "text-font": ["Open Sans Regular"],
      "text-max-width": 8,
      "text-offset": [0, 0.5],
      "text-size": 11,
    },
    minzoom: 10,
    paint: {
      "text-color": "#666",
      "text-halo-blur": 1,
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-halo-width": 1,
    },
    source: "openmaptiles",
    "source-layer": "aerodrome_label",
    type: "symbol",
  },
  {
    filter: ["==", "$type", "LineString"],
    id: "road_major_label",
    layout: {
      "symbol-placement": "line",
      "text-field": "{name}",
      "text-font": ["Open Sans Regular"],
      "text-letter-spacing": 0.1,
      "text-rotation-alignment": "map",
      "text-size": {
        base: 1.4,
        stops: [
          [10, 8],
          [20, 14],
        ],
      },
      "text-transform": "uppercase",
    },
    paint: {
      "text-color": "#000",
      "text-halo-color": "hsl(0, 0%, 100%)",
      "text-halo-width": 2,
    },
    source: "openmaptiles",
    "source-layer": "transportation_name",
    type: "symbol",
  },
  {
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!in", "class", "city", "state", "country", "continent"],
    ],
    id: "place_label_other",
    layout: {
      "text-anchor": "center",
      "text-field": "{name}",
      "text-font": ["Open Sans Regular"],
      "text-max-width": 6,
      "text-size": {
        stops: [
          [6, 10],
          [12, 14],
        ],
      },
    },
    minzoom: 8,
    paint: {
      "text-color": "hsl(0, 0%, 25%)",
      "text-halo-blur": 0,
      "text-halo-color": "hsl(0, 0%, 100%)",
      "text-halo-width": 2,
    },
    source: "openmaptiles",
    "source-layer": "place",
    type: "symbol",
  },
  {
    filter: ["all", ["==", "$type", "Point"], ["==", "class", "city"]],
    id: "place_label_city",
    layout: {
      "text-field": "{name}",
      "text-font": ["Open Sans Regular"],
      "text-max-width": 10,
      "text-size": {
        stops: [
          [3, 12],
          [8, 16],
        ],
      },
    },
    maxzoom: 14,
    paint: {
      "text-color": "hsl(0, 0%, 0%)",
      "text-halo-blur": 0,
      "text-halo-color": "hsla(0, 0%, 100%, 0.75)",
      "text-halo-width": 2,
    },
    source: "openmaptiles",
    "source-layer": "place",
    type: "symbol",
  },
  {
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "class", "country"],
      ["!has", "iso_a2"],
    ],
    id: "country_label-other",
    layout: {
      "text-field": "{name:latin}",
      "text-font": ["Open Sans Regular"],
      "text-max-width": 10,
      "text-size": {
        stops: [
          [3, 12],
          [8, 22],
        ],
      },
    },
    maxzoom: 12,
    paint: {
      "text-color": "hsl(0, 0%, 13%)",
      "text-halo-blur": 0,
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-halo-width": 2,
    },
    source: "openmaptiles",
    "source-layer": "place",
    type: "symbol",
  },
  {
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "class", "country"],
      ["has", "iso_a2"],
    ],
    id: "country_label",
    layout: {
      "text-field": "{name:latin}",
      "text-font": ["Open Sans Regular"],
      "text-max-width": 10,
      "text-size": {
        stops: [
          [3, 12],
          [8, 22],
        ],
      },
    },
    maxzoom: 12,
    paint: {
      "text-color": "hsl(0, 0%, 13%)",
      "text-halo-blur": 0,
      "text-halo-color": "rgba(255,255,255,0.75)",
      "text-halo-width": 2,
    },
    source: "openmaptiles",
    "source-layer": "place",
    type: "symbol",
  },
];

const SPOTIFY_DARK_LAYERS: Array<LayerSpecification> = [
  {
    id: "background",
    type: "background",
    layout: {
      visibility: "visible",
    },
    paint: {
      "background-color": {
        stops: [
          [6, "hsl(30, 0%, 15%)"],
          [10, "hsl(30, 0%, 15%)"],
          [14, "hsl(25, 0%, 15%)"],
          [15, "hsl(25, 0%, 15%)"],
        ],
      },
    },
  },
  {
    id: "park_outline",
    type: "line",
    source: "openmaptiles",
    "source-layer": "park",
    minzoom: 9.0,
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(90, 0%, 0%)",
      "line-width": {
        stops: [
          [9, 0.5],
          [14, 2],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [1, 1.5],
    },
    filter: ["all"],
  },
  {
    id: "landcover_cropland",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "globallandcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(321, 0%, 23%)",
      "fill-opacity": {
        base: 1,
        stops: [
          [4, 1],
          [8, 0],
        ],
      },
    },
    filter: ["all", ["==", "class", "crop"]],
  },
  {
    id: "landcover_grassland",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "globallandcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(346, 0%, 22%)",
      "fill-opacity": {
        base: 1,
        stops: [
          [4, 1],
          [8, 0],
        ],
      },
    },
    filter: ["all", ["==", "class", "grass"]],
  },
  {
    id: "landcover_scrubland",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "globallandcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(7, 0%, 21%)",
      "fill-opacity": {
        base: 1,
        stops: [
          [4, 1],
          [8, 0],
        ],
      },
    },
    filter: ["all", ["==", "class", "scrub"]],
  },
  {
    id: "landcover_treeland",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "globallandcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(10, 0%, 20%)",
      "fill-opacity": {
        base: 1,
        stops: [
          [4, 1],
          [8, 0],
        ],
      },
    },
    filter: ["all", ["==", "class", "tree"]],
  },
  {
    id: "landcover_forestland",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "globallandcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(30, 0%, 20%)",
      "fill-opacity": {
        base: 1,
        stops: [
          [4, 1],
          [8, 0],
        ],
      },
    },
    filter: ["all", ["==", "class", "forest"]],
  },
  {
    id: "landcover_snowland",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "globallandcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(343, 0%, 16%)",
      "fill-opacity": {
        base: 1,
        stops: [
          [4, 1],
          [8, 0],
        ],
      },
    },
    filter: ["all", ["==", "class", "snow"]],
  },
  {
    id: "landuse_residential",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landuse",
    maxzoom: 14.0,
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": {
        base: 1,
        stops: [
          [9, "hsla(27, 0%, 14%, 0.8)"],
          [12, "hsla(27, 0%, 14%, 0.55)"],
          [16, "hsla(27, 0%, 14%, 0.35)"],
        ],
      },
    },
    metadata: {},
    filter: ["all", ["in", "class", "residential", "suburbs", "neighbourhood"]],
  },
  {
    id: "landcover_wood",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(98, 0%, 0%)",
      "fill-opacity": 0.4,
      "fill-antialias": false,
    },
    metadata: {},
    filter: ["all", ["==", "class", "wood"]],
  },
  {
    id: "landcover_grass",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(75, 0%, 0%)",
      "fill-opacity": 0.3,
      "fill-antialias": false,
    },
    metadata: {},
    filter: ["all", ["==", "class", "grass"]],
  },
  {
    id: "landuse_cemetery",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landuse",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(58, 0%, 13%)",
    },
    metadata: {},
    filter: ["==", "class", "cemetery"],
  },
  {
    id: "landuse_hospital",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landuse",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(353, 0%, 15%)",
    },
    metadata: {},
    filter: ["==", "class", "hospital"],
  },
  {
    id: "landuse_school",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landuse",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(47, 0%, 14%)",
    },
    metadata: {},
    filter: ["==", "class", "school"],
  },
  {
    id: "landuse_stadium",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landuse",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(47, 0%, 14%)",
    },
    metadata: {},
    filter: ["all", ["in", "class", "stadium", "pitch", "track"]],
  },
  {
    id: "landuse_garage",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landuse",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(343, 0%, 15%)",
    },
    metadata: {},
    filter: ["all", ["in", "class", "garages"]],
  },
  {
    id: "landuse_dam",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landuse",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(51, 0%, 13%)",
    },
    metadata: {},
    filter: ["all", ["in", "class", "dam"]],
  },
  {
    id: "waterway_tunnel",
    type: "line",
    source: "openmaptiles",
    "source-layer": "waterway",
    minzoom: 14.0,
    layout: {
      "line-cap": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(104, 0%, 0%)",
      "line-width": {
        base: 1.3,
        stops: [
          [13, 0.5],
          [20, 6],
        ],
      },
      "line-dasharray": [2, 4],
    },
    filter: ["all", ["==", "brunnel", "tunnel"]],
  },
  {
    id: "waterway_river",
    type: "line",
    source: "openmaptiles",
    "source-layer": "waterway",
    layout: {
      "line-cap": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(104, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [11, 0.5],
          [20, 6],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "river"],
      ["!=", "brunnel", "tunnel"],
      ["!=", "intermittent", 1],
    ],
  },
  {
    id: "waterway_river_intermittent",
    type: "line",
    source: "openmaptiles",
    "source-layer": "waterway",
    layout: {
      "line-cap": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(104, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [11, 0.5],
          [20, 6],
        ],
      },
      "line-dasharray": [3, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "river"],
      ["!=", "brunnel", "tunnel"],
      ["==", "intermittent", 1],
    ],
  },
  {
    id: "waterway_other",
    type: "line",
    source: "openmaptiles",
    "source-layer": "waterway",
    layout: {
      "line-cap": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(104, 0%, 0%)",
      "line-width": {
        base: 1.3,
        stops: [
          [13, 0.5],
          [20, 6],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!=", "class", "river"],
      ["!=", "brunnel", "tunnel"],
      ["!=", "intermittent", 1],
    ],
  },
  {
    id: "waterway_other_intermittent",
    type: "line",
    source: "openmaptiles",
    "source-layer": "waterway",
    layout: {
      "line-cap": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(104, 0%, 0%)",
      "line-width": {
        base: 1.3,
        stops: [
          [13, 0.5],
          [20, 6],
        ],
      },
      "line-dasharray": [4, 3],
    },
    metadata: {},
    filter: [
      "all",
      ["!=", "class", "river"],
      ["!=", "brunnel", "tunnel"],
      ["==", "intermittent", 1],
    ],
  },
  {
    id: "water_intermittent",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "water",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(99, 0%, 0%)",
      "fill-opacity": 0.85,
    },
    metadata: {},
    filter: ["all", ["==", "intermittent", 1]],
  },
  {
    id: "water",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "water",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(98, 0%, 0%)",
    },
    metadata: {},
    filter: ["all", ["!=", "intermittent", 1], ["!=", "brunnel", "tunnel"]],
  },
  {
    id: "landcover_sand",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(35, 0%, 13%)",
      "fill-opacity": 1,
      "fill-antialias": false,
    },
    metadata: {},
    filter: ["all", ["in", "class", "sand"]],
  },
  {
    id: "landcover_sand_outline",
    type: "line",
    source: "openmaptiles",
    "source-layer": "landcover",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(35, 0%, 13%)",
      "line-width": 2,
    },
    metadata: {},
    filter: ["all", ["in", "class", "sand"]],
  },
  {
    id: "aeroway_fill",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "aeroway",
    minzoom: 11.0,
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(31, 0%, 14%)",
      "fill-opacity": 0.7,
    },
    metadata: {},
    filter: ["==", "$type", "Polygon"],
  },
  {
    id: "aeroway_runway",
    type: "line",
    source: "openmaptiles",
    "source-layer": "aeroway",
    minzoom: 11.0,
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(346, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [11, 3],
          [20, 16],
        ],
      },
    },
    metadata: {},
    filter: ["all", ["==", "$type", "LineString"], ["==", "class", "runway"]],
  },
  {
    id: "aeroway_taxiway",
    type: "line",
    source: "openmaptiles",
    "source-layer": "aeroway",
    minzoom: 11.0,
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(346, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [11, 0.5],
          [20, 6],
        ],
      },
    },
    metadata: {},
    filter: ["all", ["==", "$type", "LineString"], ["==", "class", "taxiway"]],
  },
  {
    id: "ferry",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(93, 0%, 0%)",
      "line-width": 1.1,
      "line-dasharray": [2, 2],
    },
    filter: ["all", ["in", "class", "ferry"]],
  },
  {
    id: "tunnel_motorway_link_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 1],
          [13, 3],
          [14, 4],
          [20, 15],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [0.5, 0.25],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "motorway"],
      ["==", "ramp", 1],
      ["==", "brunnel", "tunnel"],
    ],
  },
  {
    id: "tunnel_service_track_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(348, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 0.5],
          [13, 1],
          [14, 4],
          [20, 11],
        ],
      },
      "line-dasharray": [0.5, 0.25],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "service", "track"],
    ],
  },
  {
    id: "tunnel_link_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 1],
          [13, 3],
          [14, 4],
          [20, 15],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "ramp", "1"],
      ["==", "brunnel", "tunnel"],
      [">", "layer", 0],
    ],
  },
  {
    id: "tunnel_minor_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(348, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 0.5],
          [13, 1.5],
          [14, 4],
          [20, 16],
        ],
      },
      "line-dasharray": [0.5, 0.25],
    },
    metadata: {},
    filter: ["all", ["==", "brunnel", "tunnel"], ["==", "class", "minor"]],
  },
  {
    id: "tunnel_street_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(348, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 0.5],
          [13, 1],
          [14, 4],
          [20, 15],
        ],
      },
      "line-opacity": {
        stops: [
          [12, 0],
          [12.5, 1],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "street", "street_limited"],
    ],
  },
  {
    id: "tunnel_secondary_tertiary_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(327, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [8, 1.5],
          [20, 17],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [0.5, 0.25],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "secondary", "tertiary"],
    ],
  },
  {
    id: "tunnel_trunk_primary_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0.4],
          [6, 0.7],
          [7, 1.75],
          [20, 22],
        ],
      },
      "line-dasharray": [0.5, 0.25],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "primary", "trunk"],
    ],
  },
  {
    id: "tunnel_motorway_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0.4],
          [6, 0.7],
          [7, 1.75],
          [20, 22],
        ],
      },
      "line-dasharray": [0.5, 0.25],
    },
    metadata: {},
    filter: ["all", ["==", "class", "motorway"], ["==", "brunnel", "tunnel"]],
  },
  {
    id: "tunnel_path_pedestrian",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsla(355, 0%, 0%, 0.45)",
      "line-width": {
        base: 1.2,
        stops: [
          [14, 0.5],
          [20, 6],
        ],
      },
      "line-dasharray": [1, 0.75],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "tunnel"],
      ["in", "class", "path", "pedestrian"],
    ],
  },
  {
    id: "tunnel_motorway_link",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(346, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12.5, 0],
          [13, 1.5],
          [14, 2.5],
          [20, 11.5],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "motorway_link"],
      ["==", "ramp", 1],
      ["==", "brunnel", "tunnel"],
    ],
  },
  {
    id: "tunnel_service_track",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [13, 1.5],
          [16, 2],
          [20, 8],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "service", "track"],
    ],
  },
  {
    id: "tunnel_service_track_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [13, 1.5],
          [16, 2],
          [20, 8],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "service_construction", "track_construction"],
    ],
  },
  {
    id: "tunnel_link",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12.5, 0],
          [13, 1.5],
          [14, 2.5],
          [20, 11.5],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "ramp", "1"],
      ["==", "brunnel", "tunnel"],
      [">", "layer", 0],
    ],
  },
  {
    id: "tunnel_minor",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12.9, 0],
          [13, 1],
          [14, 2.5],
          [20, 13],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: ["all", ["==", "brunnel", "tunnel"], ["in", "class", "minor"]],
  },
  {
    id: "tunnel_minor_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12.9, 0],
          [13, 1],
          [14, 2.5],
          [20, 13],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "minor_construction"],
    ],
  },
  {
    id: "tunnel_secondary_tertiary",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(12, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [6.5, 0],
          [8, 0.5],
          [20, 13],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "secondary", "tertiary"],
    ],
  },
  {
    id: "tunnel_secondary_tertiary_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [6.5, 0],
          [8, 0.5],
          [20, 13],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "secondary_construction", "tertiary_construction"],
    ],
  },
  {
    id: "tunnel_trunk_primary",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "primary", "trunk"],
    ],
  },
  {
    id: "tunnel_trunk_primary_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "tunnel"],
      ["in", "class", "primary_construction", "trunk_construction"],
    ],
  },
  {
    id: "tunnel_motorway",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(347, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
    },
    metadata: {},
    filter: ["all", ["==", "class", "motorway"], ["==", "brunnel", "tunnel"]],
  },
  {
    id: "tunnel_motorway_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(347, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "motorway_construction"],
      ["==", "brunnel", "tunnel"],
    ],
  },
  {
    id: "tunnel_major_rail",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.4,
        stops: [
          [14, 0.4],
          [15, 0.75],
          [20, 2],
        ],
      },
    },
    metadata: {},
    filter: ["all", ["==", "brunnel", "tunnel"], ["in", "class", "rail"]],
  },
  {
    id: "tunnel_major_rail_hatching",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.4,
        stops: [
          [14.5, 0],
          [15, 3],
          [20, 8],
        ],
      },
      "line-dasharray": [0.2, 8],
    },
    metadata: {},
    filter: ["all", ["==", "brunnel", "tunnel"], ["==", "class", "rail"]],
  },
  {
    id: "road_area_pier",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(25, 0%, 15%)",
      "fill-antialias": true,
    },
    metadata: {},
    filter: ["all", ["==", "$type", "Polygon"], ["==", "class", "pier"]],
  },
  {
    id: "road_pier",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(25, 0%, 15%)",
      "line-width": {
        base: 1.2,
        stops: [
          [15, 1],
          [17, 4],
        ],
      },
    },
    metadata: {},
    filter: ["all", ["==", "$type", "LineString"], ["in", "class", "pier"]],
  },
  {
    id: "road_area_bridge",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsla(25, 0%, 15%, 0.6)",
      "fill-antialias": true,
    },
    metadata: {},
    filter: ["all", ["==", "$type", "Polygon"], ["==", "brunnel", "bridge"]],
  },
  {
    id: "road_area_pattern",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-pattern": "pedestrian_polygon",
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["!has", "brunnel"],
      ["!in", "class", "bridge", "pier"],
    ],
  },
  {
    id: "road_service_track_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(348, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 0.5],
          [13, 1],
          [14, 4],
          [20, 11],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "service", "track"],
    ],
  },
  {
    id: "road_link_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 13.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 1],
          [13, 3],
          [14, 4],
          [20, 15],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      [
        "!in",
        "class",
        "motorway",
        "trunk",
        "primary",
        "pedestrian",
        "path",
        "track",
        "service",
      ],
      ["==", "ramp", 1],
    ],
  },
  {
    id: "road_trunk_primary_link_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "butt",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 0.7],
          [20, 14],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["==", "ramp", 1],
      ["in", "class", "primary", "trunk"],
    ],
  },
  {
    id: "road_motorway_link_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 10.0,
    layout: {
      "line-cap": "butt",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [9.9, 0],
          [10, 1.4],
          [14, 8],
          [20, 12],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["==", "class", "motorway"],
      ["==", "ramp", 1],
    ],
  },
  {
    id: "road_minor_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(348, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 0.7],
          [13, 1],
          [14, 4],
          [20, 16],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "minor"],
      ["!=", "ramp", "1"],
    ],
  },
  {
    id: "road_secondary_tertiary_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(327, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [8, 1.5],
          [20, 17],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "secondary", "tertiary"],
      ["!=", "ramp", 1],
    ],
  },
  {
    id: "road_trunk_primary_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "butt",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0.4],
          [6, 0.7],
          [7, 1.75],
          [20, 22],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["!=", "ramp", 1],
      ["in", "class", "primary", "trunk"],
      ["!=", "ramp", 1],
    ],
  },
  {
    id: "road_motorway_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-cap": "butt",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0.4],
          [6, 0.7],
          [7, 1.75],
          [20, 22],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["==", "class", "motorway"],
      ["!=", "ramp", 1],
    ],
  },
  {
    id: "road_path_pedestrian",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 12.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsla(356, 0%, 0%, 0.45)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 1],
          [16, 2],
          [20, 3],
        ],
      },
      "line-dasharray": [1, 1],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "path", "pedestrian"],
    ],
  },
  {
    id: "road_link",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 13.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12.5, 0],
          [13, 1.5],
          [14, 2.5],
          [20, 11.5],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["==", "ramp", 1],
      [
        "!in",
        "class",
        "motorway",
        "trunk",
        "primary",
        "pedestrian",
        "path",
        "track",
        "service",
      ],
    ],
  },
  {
    id: "road_trunk_primary_link",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 0.5],
          [20, 10],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["==", "ramp", 1],
      ["in", "class", "primary", "trunk"],
    ],
  },
  {
    id: "road_motorway_link",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 10.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(346, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [9.9, 0],
          [10, 1],
          [14, 6],
          [20, 10],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["==", "class", "motorway"],
      ["==", "ramp", 1],
    ],
  },
  {
    id: "road_service_track",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 13.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [13, 1.5],
          [16, 2],
          [20, 8],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "service", "track"],
    ],
  },
  {
    id: "road_service_track_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 13.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [13, 1.5],
          [16, 2],
          [20, 8],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "service_construction", "track_construction"],
    ],
  },
  {
    id: "road_minor",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12.9, 0],
          [13, 1],
          [14, 2.5],
          [20, 13],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["!in", "brunnel", "bridge", "tunnel"], ["in", "class", "minor"]],
    ],
  },
  {
    id: "road_minor_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [14, 2.5],
          [20, 18],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "LineString"],
      [
        "all",
        ["!in", "brunnel", "bridge", "tunnel"],
        ["in", "class", "minor_construction"],
      ],
    ],
  },
  {
    id: "road_secondary_tertiary",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(12, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [6.5, 0],
          [8, 0.5],
          [20, 13],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "secondary", "tertiary"],
    ],
  },
  {
    id: "road_secondary_tertiary_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [6.5, 0],
          [8, 0.5],
          [20, 13],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "secondary_construction", "tertiary_construction"],
    ],
  },
  {
    id: "road_trunk_primary",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["!=", "ramp", 1],
      ["in", "class", "primary", "trunk"],
    ],
  },
  {
    id: "road_trunk_primary_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["!=", "ramp", 1],
      ["in", "class", "primary_construction", "trunk_construction"],
    ],
  },
  {
    id: "road_motorway",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": {
        base: 1,
        stops: [
          [5, "hsl(338, 0%, 0%)"],
          [6, "hsl(346, 0%, 0%)"],
        ],
      },
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["==", "class", "motorway"],
      ["!=", "ramp", 1],
    ],
  },
  {
    id: "road_motorway_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": {
        base: 1,
        stops: [
          [5, "hsl(338, 0%, 0%)"],
          [6, "hsl(346, 0%, 0%)"],
        ],
      },
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["==", "class", "motorway_construction"],
      ["!=", "ramp", 1],
    ],
  },
  {
    id: "road_major_rail",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.4,
        stops: [
          [14, 0.4],
          [15, 0.75],
          [20, 2],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "rail"],
    ],
  },
  {
    id: "road_major_rail_hatching",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.4,
        stops: [
          [14.5, 0],
          [15, 3],
          [20, 8],
        ],
      },
      "line-dasharray": [0.2, 8],
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["==", "class", "rail"],
    ],
  },
  {
    id: "road_minor_rail",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.4,
        stops: [
          [14, 0.4],
          [15, 0.75],
          [20, 2],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "subclass", "tram", "light_rail"],
    ],
  },
  {
    id: "road_minor_rail_hatching",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.4,
        stops: [
          [14.5, 0],
          [15, 2],
          [20, 6],
        ],
      },
      "line-dasharray": [0.2, 4],
    },
    metadata: {},
    filter: [
      "all",
      ["!in", "brunnel", "bridge", "tunnel"],
      ["in", "subclass", "tram", "light_rail"],
    ],
  },
  {
    id: "building",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "building",
    minzoom: 13.0,
    maxzoom: 14.0,
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": "hsl(30, 0%, 26%)",
      "fill-outline-color": {
        base: 1,
        stops: [
          [13, "hsl(35, 0%, 28%)"],
          [14, "hsl(35, 0%, 28%)"],
        ],
      },
    },
    metadata: {},
  },
  {
    id: "building-3d",
    type: "fill-extrusion",
    source: "openmaptiles",
    "source-layer": "building",
    minzoom: 14.0,
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-extrusion-base": {
        type: "identity",
        property: "render_min_height",
      },
      "fill-extrusion-color": "hsl(30, 0%, 26%)",
      "fill-extrusion-height": {
        type: "identity",
        property: "render_height",
      },
      "fill-extrusion-opacity": 0.3,
    },
    metadata: {},
    filter: ["all", ["!has", "hide_3d"]],
  },
  {
    id: "waterway-bridge-case",
    type: "line",
    source: "openmaptiles",
    "source-layer": "waterway",
    layout: {
      "line-cap": "butt",
      "line-join": "miter",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(254, 0%, 0%)",
      "line-width": {
        base: 1.6,
        stops: [
          [12, 0.5],
          [20, 5],
        ],
      },
      "line-gap-width": {
        base: 1.3,
        stops: [
          [13, 0.5],
          [20, 6],
        ],
      },
    },
    filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"]],
  },
  {
    id: "waterway-bridge",
    type: "line",
    source: "openmaptiles",
    "source-layer": "waterway",
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(98, 0%, 0%)",
      "line-width": {
        base: 1.3,
        stops: [
          [13, 0.5],
          [20, 6],
        ],
      },
    },
    filter: ["all", ["==", "$type", "LineString"], ["==", "brunnel", "bridge"]],
  },
  {
    id: "bridge_motorway_link_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [9.9, 0],
          [10, 1.4],
          [14, 8],
          [20, 12],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "motorway"],
      ["==", "ramp", 1],
      ["==", "brunnel", "bridge"],
    ],
  },
  {
    id: "bridge_service_track_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(348, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [13, 1.5],
          [20, 11],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "service", "track"],
    ],
  },
  {
    id: "bridge_link_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 1],
          [13, 3],
          [14, 4],
          [20, 15],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: ["all", ["==", "class", "link"], ["==", "brunnel", "bridge"]],
  },
  {
    id: "bridge_street_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(348, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 0.5],
          [13, 1],
          [14, 4],
          [20, 18],
        ],
      },
      "line-opacity": {
        stops: [
          [12, 0],
          [12.5, 1],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "street", "street_limited"],
    ],
  },
  {
    id: "bridge_path_pedestrian_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "miter",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(347, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [14, 1.5],
          [20, 18],
        ],
      },
      "line-dasharray": [1, 0],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "bridge"],
      ["in", "class", "path", "pedestrian"],
    ],
  },
  {
    id: "bridge_minor_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-cap": "butt",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(348, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12, 0.7],
          [13, 1],
          [14, 4],
          [20, 16],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["in", "brunnel", "bridge", "tunnel"],
      ["in", "class", "minor"],
      ["!=", "ramp", "1"],
    ],
  },
  {
    id: "bridge_secondary_tertiary_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(327, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [8, 1.5],
          [20, 17],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "secondary", "tertiary"],
    ],
  },
  {
    id: "bridge_trunk_primary_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0.4],
          [6, 0.7],
          [7, 1.75],
          [20, 22],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "primary", "trunk"],
    ],
  },
  {
    id: "bridge_motorway_casing",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(340, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0.4],
          [6, 0.7],
          [7, 1.75],
          [20, 22],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "motorway"],
      ["==", "brunnel", "bridge"],
      ["!=", "ramp", 1],
    ],
  },
  {
    id: "bridge_path_pedestrian",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [14, 0.5],
          [20, 10],
        ],
      },
      "line-dasharray": [1, 0.3],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "brunnel", "bridge"],
      ["in", "class", "path", "pedestrian"],
    ],
  },
  {
    id: "bridge_motorway_link",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(346, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [9.9, 0],
          [10, 1],
          [14, 6],
          [20, 10],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "motorway"],
      ["==", "ramp", 1],
      ["==", "brunnel", "bridge"],
    ],
  },
  {
    id: "bridge_service_track",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [13, 1.5],
          [16, 2],
          [20, 8],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "service", "track"],
    ],
  },
  {
    id: "bridge_service_track_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [13, 1.5],
          [16, 2],
          [20, 8],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "service_construction", "track_construction"],
    ],
  },
  {
    id: "bridge_link",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12.5, 0],
          [13, 1.5],
          [14, 2.5],
          [20, 11.5],
        ],
      },
    },
    metadata: {},
    filter: ["all", ["==", "class", "link"], ["==", "brunnel", "bridge"]],
  },
  {
    id: "bridge_street",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [13.5, 0],
          [14, 2.5],
          [20, 16],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "street", "street_limited"],
    ],
  },
  {
    id: "bridge_minor",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12.9, 0],
          [13, 1],
          [14, 2.5],
          [20, 13],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: ["all", ["==", "brunnel", "bridge"], ["in", "class", "minor"]],
  },
  {
    id: "bridge_minor_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [12.9, 0],
          [13, 1],
          [14, 2.5],
          [20, 13],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "minor_construction"],
    ],
  },
  {
    id: "bridge_secondary_tertiary",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(12, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [6.5, 0],
          [8, 0.5],
          [20, 13],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "secondary", "tertiary"],
    ],
  },
  {
    id: "bridge_secondary_tertiary_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [6.5, 0],
          [8, 0.5],
          [20, 13],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "secondary_construction", "tertiary_construction"],
    ],
  },
  {
    id: "bridge_trunk_primary",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "primary", "trunk"],
    ],
  },
  {
    id: "bridge_trunk_primary_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "brunnel", "bridge"],
      ["in", "class", "primary_construction", "trunk_construction"],
    ],
  },
  {
    id: "bridge_motorway",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(346, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "motorway"],
      ["==", "brunnel", "bridge"],
      ["!=", "ramp", 1],
    ],
  },
  {
    id: "bridge_motorway_construction",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 5.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(346, 0%, 0%)",
      "line-width": {
        base: 1.2,
        stops: [
          [5, 0],
          [7, 1],
          [20, 18],
        ],
      },
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "class", "motorway_construction"],
      ["==", "brunnel", "bridge"],
      ["!=", "ramp", 1],
    ],
  },
  {
    id: "bridge_major_rail",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.4,
        stops: [
          [14, 0.4],
          [15, 0.75],
          [20, 2],
        ],
      },
    },
    metadata: {},
    filter: ["all", ["==", "class", "rail"], ["==", "brunnel", "bridge"]],
  },
  {
    id: "bridge_major_rail_hatching",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    layout: {
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1.4,
        stops: [
          [14.5, 0],
          [15, 3],
          [20, 8],
        ],
      },
      "line-dasharray": [0.2, 8],
    },
    metadata: {},
    filter: ["all", ["==", "class", "rail"], ["==", "brunnel", "bridge"]],
  },
  {
    id: "cablecar",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 13.0,
    layout: {
      "line-cap": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1,
        stops: [
          [13, 1],
          [19, 2],
        ],
      },
    },
    filter: ["==", "class", "aerialway"],
  },
  {
    id: "cablecar-dash",
    type: "line",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 13.0,
    layout: {
      "line-cap": "round",
      "line-join": "bevel",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(312, 0%, 0%)",
      "line-width": {
        base: 1,
        stops: [
          [13, 2],
          [19, 4],
        ],
      },
      "line-dasharray": [2, 2],
    },
    filter: ["==", "class", "aerialway"],
  },
  {
    id: "boundary_3",
    type: "line",
    source: "openmaptiles",
    "source-layer": "boundary",
    minzoom: 3.0,
    layout: {
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(248, 0%, 31%)",
      "line-width": {
        base: 1,
        stops: [
          [4, 0.4],
          [5, 0.7],
          [12, 1.6],
        ],
      },
      "line-dasharray": [5, 3],
    },
    metadata: {},
    filter: ["all", ["in", "admin_level", 3, 4], ["==", "maritime", 0]],
  },
  {
    id: "boundary_2_z0-4",
    type: "line",
    source: "openmaptiles",
    "source-layer": "boundary",
    minzoom: 0.0,
    maxzoom: 5.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 26%)",
      "line-width": {
        base: 1,
        stops: [
          [3, 1],
          [5, 1.2],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "admin_level", 2],
      ["==", "maritime", 0],
      ["==", "disputed", 0],
      ["!has", "claimed_by"],
    ],
  },
  {
    id: "boundary_2_z0-4_disputed",
    type: "line",
    source: "openmaptiles",
    "source-layer": "boundary",
    minzoom: 0.0,
    maxzoom: 5.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 26%)",
      "line-width": {
        base: 1,
        stops: [
          [3, 1],
          [5, 1.2],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "admin_level", 2],
      ["==", "maritime", 0],
      ["==", "disputed", 1],
    ],
  },
  {
    id: "boundary_2_z0-4_disputed_maritime",
    type: "line",
    source: "openmaptiles",
    "source-layer": "boundary",
    minzoom: 0.0,
    maxzoom: 5.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(200, 0%, 31%)",
      "line-width": {
        base: 1,
        stops: [
          [3, 1],
          [5, 1.2],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [2, 2],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "admin_level", 2],
      ["==", "maritime", 1],
      ["==", "disputed", 1],
    ],
  },
  {
    id: "boundary_2_z5",
    type: "line",
    source: "openmaptiles",
    "source-layer": "boundary",
    minzoom: 5.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 26%)",
      "line-width": {
        base: 1,
        stops: [
          [5, 1.2],
          [12, 3],
        ],
      },
      "line-opacity": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "admin_level", 2],
      ["==", "disputed", 0],
      ["==", "maritime", 0],
    ],
  },
  {
    id: "boundary_2_z5_disputed",
    type: "line",
    source: "openmaptiles",
    "source-layer": "boundary",
    minzoom: 5.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 23%)",
      "line-width": {
        base: 1,
        stops: [
          [5, 1.2],
          [12, 3],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [4, 3],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "admin_level", 2],
      ["==", "disputed", 1],
      ["==", "maritime", 0],
    ],
  },
  {
    id: "boundary_2_z5_disputed_maritime",
    type: "line",
    source: "openmaptiles",
    "source-layer": "boundary",
    minzoom: 5.0,
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(200, 0%, 31%)",
      "line-width": {
        base: 1,
        stops: [
          [5, 1.2],
          [12, 3],
        ],
      },
      "line-opacity": 1,
      "line-dasharray": [4, 3],
    },
    metadata: {},
    filter: [
      "all",
      ["==", "admin_level", 2],
      ["==", "disputed", 1],
      ["==", "maritime", 1],
    ],
  },
  {
    id: "water_name_line",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "water_name",
    minzoom: 0.0,
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": 12,
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\u00a0",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-max-width": 5,
      "symbol-placement": "line",
    },
    paint: {
      "text-color": "hsl(132, 0%, 0%)",
      "text-halo-color": "hsla(254, 0%, 0%, 0.7)",
      "text-halo-width": 1,
    },
    metadata: {},
    filter: ["all", ["==", "$type", "LineString"]],
  },
  {
    id: "water_name_point",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "water_name",
    minzoom: 2.0,
    maxzoom: 14.0,
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": 12,
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-max-width": 5,
    },
    paint: {
      "text-color": "hsl(104, 0%, 0%)",
      "text-halo-color": "hsla(254, 0%, 0%, 0)",
      "text-halo-width": 1,
    },
    metadata: {},
    filter: ["all", ["==", "$type", "Point"], ["!=", "class", "ocean"]],
  },
  {
    id: "water_ocean_name_point",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "water_name",
    minzoom: 0.0,
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": 12,
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-max-width": 5,
    },
    paint: {
      "text-color": "hsl(104, 0%, 0%)",
      "text-halo-color": "hsla(254, 0%, 0%, 0)",
      "text-halo-width": 1,
    },
    metadata: {},
    filter: ["all", ["==", "$type", "Point"], ["==", "class", "ocean"]],
  },
  {
    id: "poi_z16_subclass",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "poi",
    minzoom: 14.0,
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": 12,
      "icon-image": "{subclass}_11",
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "none",
      "text-anchor": "top",
      "text-offset": [0, 0.6],
      "text-padding": 2,
      "text-max-width": 9,
    },
    paint: {
      "text-color": "#666",
      "text-halo-blur": 0.5,
      "text-halo-color": "#ffffff",
      "text-halo-width": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "Point"],
      [">=", "rank", 20],
      [
        "any",
        [
          "all",
          ["in", "class", "pitch"],
          [
            "in",
            "subclass",
            "soccer",
            "tennis",
            "baseball",
            "basketball",
            "swimming",
            "golf",
          ],
        ],
      ],
      ["any", ["!has", "level"], ["==", "level", 0]],
    ],
  },
  {
    id: "poi_z16",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "poi",
    minzoom: 14.0,
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": 12,
      "icon-image": "{class}_11",
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "none",
      "text-anchor": "top",
      "text-offset": [0, 0.6],
      "text-padding": 2,
      "text-max-width": 9,
    },
    paint: {
      "text-color": "#666",
      "text-halo-blur": 0.5,
      "text-halo-color": "#ffffff",
      "text-halo-width": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "class", "swimming_pool"],
      [">=", "rank", 20],
      [
        "none",
        [
          "all",
          ["in", "class", "pitch"],
          [
            "in",
            "subclass",
            "soccer",
            "tennis",
            "baseball",
            "basketball",
            "swimming",
            "golf",
          ],
        ],
      ],
      ["any", ["!has", "level"], ["==", "level", 0]],
    ],
  },
  {
    id: "poi_z15",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "poi",
    minzoom: 14.0,
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": 12,
      "icon-image": "{class}_11",
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "none",
      "text-anchor": "top",
      "text-offset": [0, 0.6],
      "text-padding": 2,
      "text-max-width": 9,
    },
    paint: {
      "text-color": "#666",
      "text-halo-blur": 0.5,
      "text-halo-color": "#ffffff",
      "text-halo-width": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "class", "swimming_pool"],
      [">=", "rank", 7],
      ["<", "rank", 20],
      ["any", ["!has", "level"], ["==", "level", 0]],
    ],
  },
  {
    id: "poi_z14",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "poi",
    minzoom: 14.0,
    layout: {
      "icon-size": 0.9,
      "text-font": ["Open Sans Italic"],
      "text-size": 12,
      "icon-image": "{class}_11",
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "none",
      "text-anchor": "top",
      "text-offset": [0, 0.6],
      "text-padding": 2,
      "text-max-width": 9,
    },
    paint: {
      "text-color": "#666",
      "text-halo-blur": 0.5,
      "text-halo-color": "#ffffff",
      "text-halo-width": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "class", "swimming_pool"],
      ["any", ["<", "rank", 7]],
      ["any", ["!has", "level"], ["==", "level", 0]],
    ],
  },
  {
    id: "poi_transit",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "poi",
    minzoom: 14.0,
    layout: {
      "icon-size": 0.9,
      "text-font": ["Open Sans Italic"],
      "text-size": 12,
      "icon-image": "{class}_11",
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "none",
      "text-anchor": "left",
      "text-offset": [0.9, 0],
      "text-padding": 2,
      "text-max-width": 9,
    },
    paint: {
      "text-color": "rgba(102, 102, 102, 1)",
      "text-halo-blur": 0.5,
      "text-halo-color": "#ffffff",
      "text-halo-width": 1,
    },
    metadata: {},
    filter: [
      "all",
      ["in", "class", "bus", "railway", "airport"],
      ["==", "subclass", "station"],
    ],
  },
  {
    id: "road_oneway",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 14.0,
    layout: {
      "icon-size": {
        stops: [
          [15, 0.5],
          [19, 1],
        ],
      },
      "icon-image": "oneway",
      visibility: "none",
      "icon-rotate": 90,
      "icon-padding": 2,
      "symbol-spacing": 75,
      "symbol-placement": "line",
      "icon-rotation-alignment": "map",
    },
    paint: {
      "icon-opacity": 0.5,
    },
    filter: [
      "all",
      ["==", "oneway", 1],
      [
        "in",
        "class",
        "motorway",
        "trunk",
        "primary",
        "secondary",
        "tertiary",
        "minor",
        "service",
      ],
    ],
  },
  {
    id: "road_oneway_opposite",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 14.0,
    layout: {
      "icon-size": {
        stops: [
          [15, 0.5],
          [19, 1],
        ],
      },
      "icon-image": "oneway",
      visibility: "none",
      "icon-rotate": -90,
      "icon-padding": 2,
      "symbol-spacing": 75,
      "symbol-placement": "line",
      "icon-rotation-alignment": "map",
    },
    paint: {
      "icon-opacity": 0.5,
    },
    filter: [
      "all",
      ["==", "oneway", -1],
      [
        "in",
        "class",
        "motorway",
        "trunk",
        "primary",
        "secondary",
        "tertiary",
        "minor",
        "service",
      ],
    ],
  },
  {
    id: "road_gondola_label",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "transportation_name",
    minzoom: 13.0,
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": {
        base: 1,
        stops: [
          [13, 11],
          [15, 12],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\u00a0",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-anchor": "center",
      "text-offset": [0.8, 0.8],
      "symbol-placement": "line",
    },
    paint: {
      "text-color": "hsl(125, 9%, 100%)",
      "text-halo-blur": 0.5,
      "text-halo-color": "hsl(98, 0%, 100%)",
      "text-halo-width": 1,
    },
    metadata: {},
    filter: ["all", ["in", "subclass", "gondola", "cable_car"]],
  },
  {
    id: "road_ferry_label",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "transportation_name",
    minzoom: 12.0,
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": {
        base: 1,
        stops: [
          [13, 11],
          [15, 12],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\u00a0",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-anchor": "center",
      "text-offset": [0.8, 0.8],
      "symbol-placement": "line",
    },
    paint: {
      "text-color": "hsl(299, 57%, 100%)",
      "text-halo-blur": 0.5,
      "text-halo-width": 1,
    },
    filter: ["all", ["==", "subclass", "ferry"]],
  },
  {
    id: "road_label",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "transportation_name",
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": {
        base: 1,
        stops: [
          [13, 11],
          [14, 13],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\u00a0",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-anchor": "center",
      "text-offset": [0, 0.15],
      "symbol-placement": "line",
    },
    paint: {
      "text-color": "hsl(128, 17%, 100%)",
      "text-halo-blur": 0.5,
      "text-halo-width": 1,
    },
    metadata: {},
    filter: ["all", ["!in", "subclass", "ferry", "gondola", "cable_car"]],
  },
  {
    id: "highway-junction",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "transportation_name",
    minzoom: 14.0,
    layout: {
      "icon-size": 1,
      "text-font": ["Open Sans Regular"],
      "text-size": 10,
      "icon-image": "circle_8",
      "text-field": "Exit {ref}",
      visibility: "visible",
      "text-anchor": "bottom-left",
      "text-offset": [0.55, -0.4],
      "text-justify": "center",
      "icon-text-fit": "none",
      "symbol-spacing": 200,
      "symbol-z-order": "auto",
      "symbol-placement": "point",
      "symbol-avoid-edges": true,
      "text-pitch-alignment": "auto",
      "icon-rotation-alignment": "viewport",
      "text-rotation-alignment": "viewport",
    },
    paint: {
      "text-color": "hsl(98, 1%, 100%)",
      "icon-opacity": 1,
    },
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "subclass", "junction"],
      [">", "ref_length", 0],
    ],
  },
  {
    id: "highway-shield",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "transportation_name",
    minzoom: 8.0,
    layout: {
      "icon-size": 1,
      "text-font": ["Open Sans Regular"],
      "text-size": 10,
      "icon-image": "road_{ref_length}",
      "text-field": "{ref}",
      visibility: "none",
      "symbol-spacing": 200,
      "symbol-placement": {
        base: 1,
        stops: [
          [10, "point"],
          [11, "line"],
        ],
      },
      "symbol-avoid-edges": true,
      "icon-rotation-alignment": "viewport",
      "text-rotation-alignment": "viewport",
    },
    paint: {
      "text-color": "rgba(37, 36, 36, 1)",
    },
    filter: [
      "all",
      ["<=", "ref_length", 6],
      ["==", "$type", "LineString"],
      ["!in", "network", "us-interstate", "us-highway", "us-state"],
    ],
  },
  {
    id: "highway-shield-us-interstate",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "transportation_name",
    minzoom: 7.0,
    layout: {
      "icon-size": 1,
      "text-font": ["Open Sans Regular"],
      "text-size": 9,
      "icon-image": "{network}_{ref_length}",
      "text-field": "{ref}",
      visibility: "none",
      "symbol-spacing": 200,
      "symbol-placement": {
        base: 1,
        stops: [
          [7, "point"],
          [7, "line"],
          [8, "line"],
        ],
      },
      "symbol-avoid-edges": true,
      "icon-rotation-alignment": "viewport",
      "text-rotation-alignment": "viewport",
    },
    paint: {
      "text-color": "rgba(255, 255, 255, 1)",
    },
    filter: [
      "all",
      ["<=", "ref_length", 6],
      ["==", "$type", "LineString"],
      ["in", "network", "us-interstate"],
    ],
  },
  {
    id: "highway-shield-us-other",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "transportation_name",
    minzoom: 9.0,
    layout: {
      "icon-size": 1,
      "text-font": ["Open Sans Regular"],
      "text-size": 9,
      "icon-image": "{network}_{ref_length}",
      "text-field": "{ref}",
      visibility: "none",
      "symbol-spacing": 200,
      "symbol-placement": {
        base: 1,
        stops: [
          [10, "point"],
          [11, "line"],
        ],
      },
      "symbol-avoid-edges": true,
      "icon-rotation-alignment": "viewport",
      "text-rotation-alignment": "viewport",
    },
    paint: {
      "text-color": "rgba(37, 36, 36, 1)",
    },
    filter: [
      "all",
      ["<=", "ref_length", 6],
      ["==", "$type", "LineString"],
      ["in", "network", "us-highway", "us-state"],
    ],
  },
  {
    id: "airport_label",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "aerodrome_label",
    minzoom: 8.5,
    layout: {
      "icon-size": 1,
      "text-font": ["Open Sans Regular"],
      "text-size": {
        stops: [
          [8, 10],
          [14, 13],
        ],
      },
      "icon-image": "airport_11",
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-anchor": "top",
      "text-offset": [0, 0.6],
      "text-padding": 2,
      "text-optional": true,
      "text-max-width": 9,
      "text-letter-spacing": 0.5,
    },
    paint: {
      "text-color": "hsl(98, 0%, 100%)",
      "text-halo-blur": 0.3,
      "text-halo-color": "hsl(98, 0%, 100%)",
      "text-halo-width": 1,
    },
    filter: ["all", ["has", "iata"]],
  },
  {
    id: "airport_gate",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "aeroway",
    minzoom: 14.0,
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": {
        stops: [
          [17, 9],
          [19, 15],
        ],
      },
      "text-field": "{ref}",
      visibility: "visible",
      "text-letter-spacing": 0.5,
    },
    paint: {
      "text-color": "hsl(98, 0%, 100%)",
      "text-halo-color": "hsl(98, 0%, 100%)",
      "text-halo-width": 0.3,
    },
    filter: ["all", ["==", "class", "gate"]],
  },
  {
    id: "place_park",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "park",
    minzoom: 12.0,
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": {
        base: 1.2,
        stops: [
          [12, 10],
          [15, 14],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "symbol-spacing": 1250,
      "text-max-width": 9,
      "text-transform": "none",
      "symbol-avoid-edges": false,
      "text-letter-spacing": 0.1,
    },
    paint: {
      "text-color": "hsl(192, 42%, 100%)",
      "text-halo-blur": 0.5,
      "text-halo-color": "hsla(98, 0%, 100%, 0.8)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: ["all", ["==", "rank", 1]],
  },
  {
    id: "place_other",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    minzoom: 8.0,
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": {
        base: 1.2,
        stops: [
          [12, 10],
          [15, 14],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-max-width": 9,
      "text-transform": "uppercase",
      "text-letter-spacing": 0.1,
    },
    paint: {
      "text-color": "hsl(98, 3%, 100%)",
      "text-halo-color": "hsla(98, 0%, 100%, 0.8)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: [
      "all",
      ["in", "class", "hamlet", "island", "islet", "neighbourhood", "suburb"],
    ],
  },
  {
    id: "place_village",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    minzoom: 8.0,
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": {
        base: 1.2,
        stops: [
          [10, 12],
          [15, 22],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-max-width": 8,
      "text-letter-spacing": 0.15,
    },
    paint: {
      "text-color": "hsl(98, 0%, 100%)",
      "text-halo-color": "hsla(98, 0%, 100%, 0.8)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: ["all", ["==", "class", "village"]],
  },
  {
    id: "place_town",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    minzoom: 6.0,
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": {
        base: 1.2,
        stops: [
          [7, 12],
          [11, 16],
        ],
      },
      "icon-image": {
        base: 1,
        stops: [
          [0, "circle-stroked_16"],
          [10, ""],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-anchor": "bottom",
      "text-offset": [0, 0],
      "text-max-width": 8,
      "text-letter-spacing": 0.15,
    },
    paint: {
      "text-color": "hsl(98, 0%, 100%)",
      "text-halo-color": "hsla(98, 0%, 100%, 0.8)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: ["all", ["==", "class", "town"]],
  },
  {
    id: "place_region",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    minzoom: 5.0,
    maxzoom: 7.0,
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": {
        stops: [
          [3, 9],
          [5, 10],
          [6, 11],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-padding": 2,
      "text-transform": "uppercase",
      "text-letter-spacing": 0.15,
    },
    paint: {
      "text-color": "hsl(146, 4%, 100%)",
      "text-halo-color": "hsla(98, 0%, 100%, 0.7)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: ["all", ["==", "class", "state"], ["in", "rank", 3, 4, 5]],
  },
  {
    id: "place_state",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    minzoom: 3.0,
    maxzoom: 6.0,
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": {
        stops: [
          [3, 9],
          [5, 11],
          [6, 12],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-padding": 2,
      "text-transform": "uppercase",
      "text-letter-spacing": 0.15,
    },
    paint: {
      "text-color": "hsl(146, 4%, 100%)",
      "text-halo-color": "hsla(98, 0%, 100%, 0.7)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: ["all", ["==", "class", "state"], ["in", "rank", 1, 2]],
  },
  {
    id: "place_city",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    minzoom: 5.0,
    layout: {
      "text-font": ["Open Sans Regular"],
      "text-size": {
        base: 1.2,
        stops: [
          [7, 14],
          [11, 24],
        ],
      },
      "icon-image": {
        base: 1,
        stops: [
          [0, "circle-stroked_16"],
          [10, ""],
        ],
      },
      "text-field": [
        "concat",
        ["get", "name:latin"],
        "\n",
        ["get", "name:nonlatin"],
      ],
      visibility: "visible",
      "text-anchor": "bottom",
      "text-offset": [0, 0],
      "icon-optional": false,
      "text-max-width": 8,
      "icon-allow-overlap": true,
      "text-letter-spacing": 0.15,
    },
    paint: {
      "text-color": "hsl(98, 0%, 100%)",
      "text-halo-color": "hsla(98, 0%, 100%, 0.8)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: ["all", ["==", "class", "city"]],
  },
  {
    id: "country_other",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": {
        stops: [
          [3, 9],
          [7, 15],
        ],
      },
      "text-field": "{name:latin}",
      visibility: "visible",
      "text-max-width": 6.25,
      "text-transform": "none",
      "text-letter-spacing": 0.1,
    },
    paint: {
      "text-color": "hsl(338, 14%, 100%)",
      "text-halo-blur": 1,
      "text-halo-color": "hsla(98, 0%, 100%, 0.8)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: ["all", ["==", "class", "country"], ["!has", "iso_a2"]],
  },
  {
    id: "country_3",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": {
        stops: [
          [3, 11],
          [7, 17],
        ],
      },
      "text-field": "{name:latin}",
      visibility: "visible",
      "text-max-width": 6.25,
      "text-transform": "none",
      "text-letter-spacing": 0.1,
    },
    paint: {
      "text-color": "hsl(338, 14%, 100%)",
      "text-halo-blur": 1,
      "text-halo-color": "hsla(98, 0%, 100%, 0.8)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: [
      "all",
      [">=", "rank", 3],
      ["==", "class", "country"],
      ["has", "iso_a2"],
    ],
  },
  {
    id: "country_2",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": {
        stops: [
          [2, 11],
          [5, 17],
        ],
      },
      "text-field": "{name:latin}",
      visibility: "visible",
      "text-max-width": 6.25,
      "text-transform": "none",
      "text-letter-spacing": 0.1,
    },
    paint: {
      "text-color": "hsl(338, 14%, 100%)",
      "text-halo-blur": 1,
      "text-halo-color": "hsla(98, 0%, 100%, 0.8)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "rank", 2],
      ["==", "class", "country"],
      ["has", "iso_a2"],
    ],
  },
  {
    id: "country_1",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": {
        stops: [
          [1, 11],
          [4, 17],
          [6, 19],
        ],
      },
      "text-field": "{name:latin}",
      visibility: "visible",
      "text-max-width": 6.25,
      "text-transform": "none",
      "text-letter-spacing": 0.1,
    },
    paint: {
      "text-color": "hsl(338, 14%, 100%)",
      "text-halo-blur": 1,
      "text-halo-color": "hsla(98, 0%, 100%, 0.8)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: [
      "all",
      ["==", "rank", 1],
      ["==", "class", "country"],
      ["has", "iso_a2"],
    ],
  },
  {
    id: "continent",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 1.0,
    layout: {
      "text-font": ["Open Sans Italic"],
      "text-size": 13,
      "text-field": "{name:latin}",
      visibility: "visible",
      "text-justify": "center",
      "text-transform": "uppercase",
      "text-letter-spacing": 0.1,
    },
    paint: {
      "text-color": "hsl(98, 33%, 100%)",
      "text-halo-color": "hsla(98, 0%, 100%, 0.7)",
      "text-halo-width": 0.3,
    },
    metadata: {},
    filter: ["all", ["==", "class", "continent"]],
  },
];

const POSITRON_LAYERS: Array<LayerSpecification> = [
  {
    id: "background",
    type: "background",
    paint: { "background-color": "rgb(242,243,240)" },
  },
  {
    id: "park",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "park",
    filter: ["==", "$type", "Polygon"],
    layout: { visibility: "visible" },
    paint: { "fill-color": "rgb(230, 233, 229)" },
  },
  {
    id: "water",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "water",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "brunnel", "tunnel"]],
    layout: { visibility: "visible" },
    paint: { "fill-antialias": true, "fill-color": "rgb(194, 200, 202)" },
  },
  {
    id: "landcover_ice_shelf",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landcover",
    maxzoom: 8,
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["==", "subclass", "ice_shelf"],
    ],
    layout: { visibility: "visible" },
    paint: { "fill-color": "hsl(0, 0%, 98%)", "fill-opacity": 0.7 },
  },
  {
    id: "landcover_glacier",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landcover",
    maxzoom: 8,
    filter: ["all", ["==", "$type", "Polygon"], ["==", "subclass", "glacier"]],
    layout: { visibility: "visible" },
    paint: {
      "fill-color": "hsl(0, 0%, 98%)",
      "fill-opacity": {
        base: 1,
        stops: [
          [0, 1],
          [8, 0.5],
        ],
      },
    },
  },
  {
    id: "landuse_residential",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landuse",
    maxzoom: 14,
    filter: ["all", ["==", "$type", "Polygon"], ["==", "class", "residential"]],
    layout: { visibility: "visible" },
    paint: {
      "fill-color": "rgb(234, 234, 230)",
      "fill-opacity": {
        base: 0.6,
        stops: [
          [8, 0.8],
          [9, 0.6],
        ],
      },
    },
  },
  {
    id: "landcover_wood",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "landcover",
    minzoom: 10,
    filter: ["all", ["==", "$type", "Polygon"], ["==", "class", "wood"]],
    layout: { visibility: "visible" },
    paint: {
      "fill-color": "rgb(220,224,220)",
      "fill-opacity": {
        base: 1,
        stops: [
          [8, 0],
          [12, 1],
        ],
      },
    },
  },
  {
    id: "waterway",
    type: "line",
    source: "openmaptiles",
    "source-layer": "waterway",
    filter: ["==", "$type", "LineString"],
    layout: { visibility: "visible" },
    paint: { "line-color": "hsl(195, 17%, 78%)" },
  },
  {
    id: "water_name",
    type: "symbol",
    source: "openmaptiles",
    "source-layer": "water_name",
    filter: ["==", "$type", "LineString"],
    layout: {
      "symbol-placement": "line",
      "symbol-spacing": 500,
      "text-field": "{name:latin}\n{name:nonlatin}",
      "text-font": ["Open Sans Italic"],
      "text-rotation-alignment": "map",
      "text-size": 12,
    },
    paint: {
      "text-color": "rgb(157,169,177)",
      "text-halo-blur": 1,
      "text-halo-color": "rgb(242,243,240)",
      "text-halo-width": 1,
    },
  },
  {
    id: "building",
    type: "fill",
    source: "openmaptiles",
    "source-layer": "building",
    minzoom: 12,
    paint: {
      "fill-antialias": true,
      "fill-color": "rgb(234, 234, 229)",
      "fill-outline-color": "rgb(219, 219, 218)",
    },
  },
  {
    id: "tunnel_motorway_casing",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 6,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["==", "brunnel", "tunnel"], ["==", "class", "motorway"]],
    ],
    layout: {
      "line-cap": "butt",
      "line-join": "miter",
      visibility: "visible",
    },
    paint: {
      "line-color": "rgb(213, 213, 213)",
      "line-opacity": 1,
      "line-width": {
        base: 1.4,
        stops: [
          [5.8, 0],
          [6, 3],
          [20, 40],
        ],
      },
    },
  },
  {
    id: "tunnel_motorway_inner",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 6,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["==", "brunnel", "tunnel"], ["==", "class", "motorway"]],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "rgb(234,234,234)",
      "line-width": {
        base: 1.4,
        stops: [
          [4, 2],
          [6, 1.3],
          [20, 30],
        ],
      },
    },
  },
  {
    id: "aeroway-taxiway",
    type: "line",
    metadata: { "mapbox:group": "1444849345966.4436" },
    source: "openmaptiles",
    "source-layer": "aeroway",
    minzoom: 12,
    filter: ["all", ["in", "class", "taxiway"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 88%)",
      "line-opacity": 1,
      "line-width": {
        base: 1.55,
        stops: [
          [13, 1.8],
          [20, 20],
        ],
      },
    },
  },
  {
    id: "aeroway-runway-casing",
    type: "line",
    metadata: { "mapbox:group": "1444849345966.4436" },
    source: "openmaptiles",
    "source-layer": "aeroway",
    minzoom: 11,
    filter: ["all", ["in", "class", "runway"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 88%)",
      "line-opacity": 1,
      "line-width": {
        base: 1.5,
        stops: [
          [11, 6],
          [17, 55],
        ],
      },
    },
  },
  {
    id: "aeroway-area",
    type: "fill",
    metadata: { "mapbox:group": "1444849345966.4436" },
    source: "openmaptiles",
    "source-layer": "aeroway",
    minzoom: 4,
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["in", "class", "runway", "taxiway"],
    ],
    layout: { visibility: "visible" },
    paint: {
      "fill-color": "rgba(255, 255, 255, 1)",
      "fill-opacity": {
        base: 1,
        stops: [
          [13, 0],
          [14, 1],
        ],
      },
    },
  },
  {
    id: "aeroway-runway",
    type: "line",
    metadata: { "mapbox:group": "1444849345966.4436" },
    source: "openmaptiles",
    "source-layer": "aeroway",
    minzoom: 11,
    filter: ["all", ["in", "class", "runway"], ["==", "$type", "LineString"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "rgba(255, 255, 255, 1)",
      "line-opacity": 1,
      "line-width": {
        base: 1.5,
        stops: [
          [11, 4],
          [17, 50],
        ],
      },
    },
  },
  {
    id: "road_area_pier",
    type: "fill",
    metadata: {},
    source: "openmaptiles",
    "source-layer": "transportation",
    filter: ["all", ["==", "$type", "Polygon"], ["==", "class", "pier"]],
    layout: { visibility: "visible" },
    paint: { "fill-antialias": true, "fill-color": "rgb(242,243,240)" },
  },
  {
    id: "road_pier",
    type: "line",
    metadata: {},
    source: "openmaptiles",
    "source-layer": "transportation",
    filter: ["all", ["==", "$type", "LineString"], ["in", "class", "pier"]],
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-color": "rgb(242,243,240)",
      "line-width": {
        base: 1.2,
        stops: [
          [15, 1],
          [17, 4],
        ],
      },
    },
  },
  {
    id: "highway_path",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    filter: ["all", ["==", "$type", "LineString"], ["==", "class", "path"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "rgb(234, 234, 234)",
      "line-opacity": 0.9,
      "line-width": {
        base: 1.2,
        stops: [
          [13, 1],
          [20, 10],
        ],
      },
    },
  },
  {
    id: "highway_minor",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 8,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["in", "class", "minor", "service", "track"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsl(0, 0%, 88%)",
      "line-opacity": 0.9,
      "line-width": {
        base: 1.55,
        stops: [
          [13, 1.8],
          [20, 20],
        ],
      },
    },
  },
  {
    id: "highway_major_casing",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 11,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["in", "class", "primary", "secondary", "tertiary", "trunk"],
    ],
    layout: {
      "line-cap": "butt",
      "line-join": "miter",
      visibility: "visible",
    },
    paint: {
      "line-color": "rgb(213, 213, 213)",
      "line-dasharray": [12, 0],
      "line-width": {
        base: 1.3,
        stops: [
          [10, 3],
          [20, 23],
        ],
      },
    },
  },
  {
    id: "highway_major_inner",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 11,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["in", "class", "primary", "secondary", "tertiary", "trunk"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "#fff",
      "line-width": {
        base: 1.3,
        stops: [
          [10, 2],
          [20, 20],
        ],
      },
    },
  },
  {
    id: "highway_major_subtle",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    maxzoom: 11,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["in", "class", "primary", "secondary", "tertiary", "trunk"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: { "line-color": "hsla(0, 0%, 85%, 0.69)", "line-width": 2 },
  },
  {
    id: "highway_motorway_casing",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 6,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      [
        "all",
        ["!in", "brunnel", "bridge", "tunnel"],
        ["==", "class", "motorway"],
      ],
    ],
    layout: {
      "line-cap": "butt",
      "line-join": "miter",
      visibility: "visible",
    },
    paint: {
      "line-color": "rgb(213, 213, 213)",
      "line-dasharray": [2, 0],
      "line-opacity": 1,
      "line-width": {
        base: 1.4,
        stops: [
          [5.8, 0],
          [6, 3],
          [20, 40],
        ],
      },
    },
  },
  {
    id: "highway_motorway_inner",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 6,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      [
        "all",
        ["!in", "brunnel", "bridge", "tunnel"],
        ["==", "class", "motorway"],
      ],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": {
        base: 1,
        stops: [
          [5.8, "hsla(0, 0%, 85%, 0.53)"],
          [6, "#fff"],
        ],
      },
      "line-width": {
        base: 1.4,
        stops: [
          [4, 2],
          [6, 1.3],
          [20, 30],
        ],
      },
    },
  },
  {
    id: "highway_motorway_subtle",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    maxzoom: 6,
    filter: ["all", ["==", "$type", "LineString"], ["==", "class", "motorway"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": "hsla(0, 0%, 85%, 0.53)",
      "line-width": {
        base: 1.4,
        stops: [
          [4, 2],
          [6, 1.3],
        ],
      },
    },
  },
  {
    id: "railway_transit",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 14,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["==", "class", "transit"], ["!in", "brunnel", "tunnel"]],
    ],
    layout: { "line-join": "round", visibility: "visible" },
    paint: { "line-color": "#dddddd", "line-width": 3 },
  },
  {
    id: "railway_transit_dashline",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 14,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["==", "class", "transit"], ["!in", "brunnel", "tunnel"]],
    ],
    layout: { "line-join": "round", visibility: "visible" },
    paint: {
      "line-color": "#fafafa",
      "line-dasharray": [3, 3],
      "line-width": 2,
    },
  },
  {
    id: "railway_service",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 14,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["==", "class", "rail"], ["has", "service"]],
    ],
    layout: { "line-join": "round", visibility: "visible" },
    paint: { "line-color": "#dddddd", "line-width": 3 },
  },
  {
    id: "railway_service_dashline",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 14,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "class", "rail"],
      ["has", "service"],
    ],
    layout: { "line-join": "round", visibility: "visible" },
    paint: {
      "line-color": "#fafafa",
      "line-dasharray": [3, 3],
      "line-width": 2,
    },
  },
  {
    id: "railway",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 13,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["!has", "service"], ["==", "class", "rail"]],
    ],
    layout: { "line-join": "round", visibility: "visible" },
    paint: {
      "line-color": "#dddddd",
      "line-width": {
        base: 1.3,
        stops: [
          [16, 3],
          [20, 7],
        ],
      },
    },
  },
  {
    id: "railway_dashline",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 13,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["!has", "service"], ["==", "class", "rail"]],
    ],
    layout: { "line-join": "round", visibility: "visible" },
    paint: {
      "line-color": "#fafafa",
      "line-dasharray": [3, 3],
      "line-width": {
        base: 1.3,
        stops: [
          [16, 2],
          [20, 6],
        ],
      },
    },
  },
  {
    id: "highway_motorway_bridge_casing",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 6,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["==", "brunnel", "bridge"], ["==", "class", "motorway"]],
    ],
    layout: {
      "line-cap": "butt",
      "line-join": "miter",
      visibility: "visible",
    },
    paint: {
      "line-color": "rgb(213, 213, 213)",
      "line-dasharray": [2, 0],
      "line-opacity": 1,
      "line-width": {
        base: 1.4,
        stops: [
          [5.8, 0],
          [6, 5],
          [20, 45],
        ],
      },
    },
  },
  {
    id: "highway_motorway_bridge_inner",
    type: "line",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation",
    minzoom: 6,
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["all", ["==", "brunnel", "bridge"], ["==", "class", "motorway"]],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-color": {
        base: 1,
        stops: [
          [5.8, "hsla(0, 0%, 85%, 0.53)"],
          [6, "#fff"],
        ],
      },
      "line-width": {
        base: 1.4,
        stops: [
          [4, 2],
          [6, 1.3],
          [20, 30],
        ],
      },
    },
  },
  {
    id: "highway_name_other",
    type: "symbol",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation_name",
    filter: ["all", ["!=", "class", "motorway"], ["==", "$type", "LineString"]],
    layout: {
      "symbol-placement": "line",
      "symbol-spacing": 350,
      "text-field": "{name:latin} {name:nonlatin}",
      "text-font": ["Open Sans Regular"],
      "text-max-angle": 30,
      "text-pitch-alignment": "viewport",
      "text-rotation-alignment": "map",
      "text-size": 10,
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "text-color": "#bbb",
      "text-halo-blur": 1,
      "text-halo-color": "#fff",
      "text-halo-width": 2,
      "text-translate": [0, 0],
    },
  },
  {
    id: "highway_name_motorway",
    type: "symbol",
    metadata: { "mapbox:group": "b6371a3f2f5a9932464fa3867530a2e5" },
    source: "openmaptiles",
    "source-layer": "transportation_name",
    filter: ["all", ["==", "$type", "LineString"], ["==", "class", "motorway"]],
    layout: {
      "symbol-placement": "line",
      "symbol-spacing": 350,
      "text-field": "{ref}",
      "text-font": ["Open Sans Regular"],
      "text-pitch-alignment": "viewport",
      "text-rotation-alignment": "viewport",
      "text-size": 10,
      visibility: "visible",
    },
    paint: {
      "text-color": "rgb(117, 129, 145)",
      "text-halo-blur": 1,
      "text-halo-color": "hsl(0, 0%, 100%)",
      "text-halo-width": 1,
      "text-translate": [0, 2],
    },
  },
  {
    id: "boundary_state",
    type: "line",
    metadata: { "mapbox:group": "a14c9607bc7954ba1df7205bf660433f" },
    source: "openmaptiles",
    "source-layer": "boundary",
    filter: ["==", "admin_level", 4],
    layout: {
      "line-cap": "round",
      "line-join": "round",
      visibility: "visible",
    },
    paint: {
      "line-blur": 0.4,
      "line-color": "rgb(230, 204, 207)",
      "line-dasharray": [2, 2],
      "line-opacity": 1,
      "line-width": {
        base: 1.3,
        stops: [
          [3, 1],
          [22, 15],
        ],
      },
    },
  },
  {
    id: "boundary_country_z0-4",
    type: "line",
    metadata: { "mapbox:group": "a14c9607bc7954ba1df7205bf660433f" },
    source: "openmaptiles",
    "source-layer": "boundary",
    maxzoom: 5,
    filter: ["all", ["==", "admin_level", 2], ["!has", "claimed_by"]],
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-blur": {
        base: 1,
        stops: [
          [0, 0.4],
          [22, 4],
        ],
      },
      "line-color": "rgb(230, 204, 207)",
      "line-opacity": 1,
      "line-width": {
        base: 1.1,
        stops: [
          [3, 1],
          [22, 20],
        ],
      },
    },
  },
  {
    id: "boundary_country_z5-",
    type: "line",
    metadata: { "mapbox:group": "a14c9607bc7954ba1df7205bf660433f" },
    source: "openmaptiles",
    "source-layer": "boundary",
    minzoom: 5,
    filter: ["==", "admin_level", 2],
    layout: { "line-cap": "round", "line-join": "round" },
    paint: {
      "line-blur": {
        base: 1,
        stops: [
          [0, 0.4],
          [22, 4],
        ],
      },
      "line-color": "rgb(230, 204, 207)",
      "line-opacity": 1,
      "line-width": {
        base: 1.1,
        stops: [
          [3, 1],
          [22, 20],
        ],
      },
    },
  },
  {
    id: "place_other",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 14,
    filter: [
      "all",
      [
        "in",
        "class",
        "continent",
        "hamlet",
        "neighbourhood",
        "isolated_dwelling",
      ],
      ["==", "$type", "Point"],
    ],
    layout: {
      "text-anchor": "center",
      "text-field": "{name:latin}\n{name:nonlatin}",
      "text-font": ["Open Sans Regular"],
      "text-justify": "center",
      "text-offset": [0.5, 0],
      "text-size": 10,
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "text-color": "rgb(117, 129, 145)",
      "text-halo-blur": 1,
      "text-halo-color": "rgb(242,243,240)",
      "text-halo-width": 1,
    },
  },
  {
    id: "place_suburb",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 14,
    filter: ["all", ["==", "$type", "Point"], ["==", "class", "suburb"]],
    layout: {
      "text-anchor": "center",
      "text-field": "{name:latin}\n{name:nonlatin}",
      "text-font": ["Open Sans Regular"],
      "text-justify": "center",
      "text-offset": [0.5, 0],
      "text-size": 10,
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "text-color": "rgb(117, 129, 145)",
      "text-halo-blur": 1,
      "text-halo-color": "rgb(242,243,240)",
      "text-halo-width": 1,
    },
  },
  {
    id: "place_village",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 14,
    filter: ["all", ["==", "$type", "Point"], ["==", "class", "village"]],
    layout: {
      "icon-size": 0.4,
      "text-anchor": "left",
      "text-field": "{name:latin}\n{name:nonlatin}",
      "text-font": ["Open Sans Regular"],
      "text-justify": "left",
      "text-offset": [0.5, 0.2],
      "text-size": 10,
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "icon-opacity": 0.7,
      "text-color": "rgb(117, 129, 145)",
      "text-halo-blur": 1,
      "text-halo-color": "rgb(242,243,240)",
      "text-halo-width": 1,
    },
  },
  {
    id: "place_town",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 14,
    filter: ["all", ["==", "$type", "Point"], ["==", "class", "town"]],
    layout: {
      "icon-image": {
        base: 1,
        stops: [
          [0, "circle-11"],
          [8, ""],
        ],
      },
      "icon-size": 0.4,
      "text-anchor": {
        base: 1,
        stops: [
          [0, "left"],
          [8, "center"],
        ],
      },
      "text-field": "{name:latin}\n{name:nonlatin}",
      "text-font": ["Open Sans Regular"],
      "text-justify": "left",
      "text-offset": [0.5, 0.2],
      "text-size": 10,
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "icon-opacity": 0.7,
      "text-color": "rgb(117, 129, 145)",
      "text-halo-blur": 1,
      "text-halo-color": "rgb(242,243,240)",
      "text-halo-width": 1,
    },
  },
  {
    id: "place_city",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 14,
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["all", ["!=", "capital", 2], ["==", "class", "city"], [">", "rank", 3]],
    ],
    layout: {
      "icon-image": {
        base: 1,
        stops: [
          [0, "circle-11"],
          [8, ""],
        ],
      },
      "icon-size": 0.4,
      "text-anchor": {
        base: 1,
        stops: [
          [0, "left"],
          [8, "center"],
        ],
      },
      "text-field": "{name:latin}\n{name:nonlatin}",
      "text-font": ["Open Sans Regular"],
      "text-justify": "left",
      "text-offset": [0.5, 0.2],
      "text-size": 10,
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "icon-opacity": 0.7,
      "text-color": "rgb(117, 129, 145)",
      "text-halo-blur": 1,
      "text-halo-color": "rgb(242,243,240)",
      "text-halo-width": 1,
    },
  },
  {
    id: "place_capital",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 12,
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["all", ["==", "capital", 2], ["==", "class", "city"]],
    ],
    layout: {
      "icon-image": {
        base: 1,
        stops: [
          [0, "star-11"],
          [8, ""],
        ],
      },
      "icon-size": 1,
      "text-anchor": {
        base: 1,
        stops: [
          [0, "left"],
          [8, "center"],
        ],
      },
      "text-field": "{name:latin}\n{name:nonlatin}",
      "text-font": ["Open Sans Regular"],
      "text-justify": "left",
      "text-offset": [0.5, 0.2],
      "text-size": 14,
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "icon-opacity": 0.7,
      "text-color": "rgb(117, 129, 145)",
      "text-halo-blur": 1,
      "text-halo-color": "rgb(242,243,240)",
      "text-halo-width": 1,
    },
  },
  {
    id: "place_city_large",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 12,
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["all", ["!=", "capital", 2], ["<=", "rank", 3], ["==", "class", "city"]],
    ],
    layout: {
      "icon-image": {
        base: 1,
        stops: [
          [0, "circle-11"],
          [8, ""],
        ],
      },
      "icon-size": 0.4,
      "text-anchor": {
        base: 1,
        stops: [
          [0, "left"],
          [8, "center"],
        ],
      },
      "text-field": "{name:latin}\n{name:nonlatin}",
      "text-font": ["Open Sans Regular"],
      "text-justify": "left",
      "text-offset": [0.5, 0.2],
      "text-size": 14,
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "icon-opacity": 0.7,
      "text-color": "rgb(117, 129, 145)",
      "text-halo-blur": 1,
      "text-halo-color": "rgb(242,243,240)",
      "text-halo-width": 1,
    },
  },
  {
    id: "place_state",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 12,
    filter: ["all", ["==", "$type", "Point"], ["==", "class", "state"]],
    layout: {
      "text-field": "{name:latin}\n{name:nonlatin}",
      "text-font": ["Open Sans Regular"],
      "text-size": 10,
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "text-color": "rgb(113, 129, 144)",
      "text-halo-blur": 1,
      "text-halo-color": "rgb(242,243,240)",
      "text-halo-width": 1,
    },
  },
  {
    id: "place_country_other",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 8,
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "class", "country"],
      ["!has", "iso_a2"],
    ],
    layout: {
      "text-field": "{name:latin}",
      "text-font": ["Open Sans Italic"],
      "text-size": {
        base: 1,
        stops: [
          [0, 9],
          [6, 11],
        ],
      },
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "text-color": {
        base: 1,
        stops: [
          [3, "rgb(157,169,177)"],
          [4, "rgb(153, 153, 153)"],
        ],
      },
      "text-halo-color": "rgba(236,236,234,0.7)",
      "text-halo-width": 1.4,
    },
  },
  {
    id: "place_country_minor",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 8,
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "class", "country"],
      [">=", "rank", 2],
      ["has", "iso_a2"],
    ],
    layout: {
      "text-field": "{name:latin}",
      "text-font": ["Open Sans Regular"],
      "text-size": {
        base: 1,
        stops: [
          [0, 10],
          [6, 12],
        ],
      },
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "text-color": {
        base: 1,
        stops: [
          [3, "rgb(157,169,177)"],
          [4, "rgb(153, 153, 153)"],
        ],
      },
      "text-halo-color": "rgba(236,236,234,0.7)",
      "text-halo-width": 1.4,
    },
  },
  {
    id: "place_country_major",
    type: "symbol",
    metadata: { "mapbox:group": "101da9f13b64a08fa4b6ac1168e89e5f" },
    source: "openmaptiles",
    "source-layer": "place",
    maxzoom: 6,
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["<=", "rank", 1],
      ["==", "class", "country"],
      ["has", "iso_a2"],
    ],
    layout: {
      "text-anchor": "center",
      "text-field": "{name:latin}",
      "text-font": ["Open Sans Regular"],
      "text-size": {
        base: 1.4,
        stops: [
          [0, 10],
          [3, 12],
          [4, 14],
        ],
      },
      "text-transform": "uppercase",
      visibility: "visible",
    },
    paint: {
      "text-color": {
        base: 1,
        stops: [
          [3, "rgb(157,169,177)"],
          [4, "rgb(153, 153, 153)"],
        ],
      },
      "text-halo-color": "rgba(236,236,234,0.7)",
      "text-halo-width": 1.4,
    },
  },
];

export const LAYER_THEMED_DATA = {
  spotify_dark: SPOTIFY_DARK_LAYERS,
  light: LIGHT_LAYERS,
  positron: POSITRON_LAYERS,
};
