import {
  RGFeatureSearchMetadata,
  RGFeatureCollection,
  Position,
} from "../types/geojson";
import { geoMercator, geoPath } from "d3-geo";
import { topology } from "topojson-server";
import { API_URL } from "settings";
import { analyticsSearch } from "utils";
import { getFeaturesPolylabel } from "utils/geojson";

export function getUserLocation(): Promise<string> {
  return fetch("https://extreme-ip-lookup.com/json/")
    .then((res) => res.json())
    .then((response) => {
      if (response.status === "success") {
        return response.country;
      }
      return "United States";
    })
    .catch(() => {
      return "United States";
    });
}

const filterOutUSACoordinates = (line: Position[]) => {
  return line.filter((x) => x[0] < 0);
};

export function getGeojson(url: string): Promise<RGFeatureCollection> {
  return fetch(`${API_URL}app/feature/${url}`)
    .then((r) => r.json())
    .then((r: RGFeatureCollection) => {
      const USAFeature = r.features.find((x) => x.id === "USA");
      if (USAFeature) {
        if (USAFeature.geometry.type === "MultiPolygon") {
          USAFeature.geometry.coordinates = USAFeature.geometry.coordinates.map(
            (polygon) =>
              polygon
                .map((line) => filterOutUSACoordinates(line))
                .filter((line) => line.length)
          );
        }
      }
      return r;
    });
}

export function searchLocations(q: string): Promise<RGFeatureSearchMetadata[]> {
  analyticsSearch(q);
  return fetch(`${API_URL}app/search/${q}`)
    .then((r) => r.json())
    .then((r) => (Array.isArray(r.data) ? r.data : []));
}

export function mergeGeojson(
  geojsons: RGFeatureCollection[]
): RGFeatureCollection {
  //@ts-ignore
  return geojsons.reduce((result: RGFeatureCollection | null, geojson) => {
    if (!result) {
      if (geojson) return geojson;
      return result;
    }
    return {
      ...result,
      features: [...result.features, ...(geojson?.features || [])],
    };
  }, null);
}

export function downloadFile(content: string, type: string, extension: string) {
  if (navigator.msSaveBlob) {
    // IE10+
    var blob = new Blob([content], {
      type,
    });
    return navigator.msSaveBlob(blob, `restgis${extension}`);
  }

  const dataStr = !content.includes('data:image') ? `data:application/json;charset=utf-8,${encodeURIComponent(content)}` : content;
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", `restgis${extension}`);
  downloadAnchorNode.innerText = "restgis";
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function geojson2Topojson(geojson: RGFeatureCollection) {
  if (geojson) {
    const topojson = topology({ restgis: geojson });
    if (topojson) {
      return JSON.stringify(topojson);
    }
  }
  return false;
}

export const HSLToRGB = (hsl: string) => {
  if (hsl !== 'transparent') {
    let [h, s, l]: (number)[] = hsl.split(', ')
      .map((item) => Number(item.replace(/[hsl(|%|%)]/gi, '')));
    s /= 100;
    l /= 100;
    const k = (n: number) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number) =>
      l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return [255 * f(0), 255 * f(8), 255 * f(4)];
  }
  return [255, 255, 255];
};

export function geojson2SVG(geojson: RGFeatureCollection) {
  if (geojson) {
    const width = 500, height = 500;
    const projection = geoMercator().fitSize([width, height], geojson);
    const generator = geoPath().projection(projection);
    const geojsonPolylabel = getFeaturesPolylabel(geojson);

    const texts = geojsonPolylabel.features.map(
      (f) => {
        const polylabelsSvg = `<svg 
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 ${width} ${height}" 
            version="1.2" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            width="${width}" 
            height="${height}"
            id="polylabel-svg"
          >
            <path
              id="${f.properties.id}"
              d="${generator(f)}"
              data-id="${f.properties.id}"
              data-name="${f.properties.name}"
              stroke="black"
              stroke-linecap="round"
            />
            \n\t\t
          </svg>`;
        document.body.insertAdjacentHTML('beforeEnd', polylabelsSvg);
        const docSvg = document.getElementById("polylabel-svg");
        if (docSvg) {
          const bbox = docSvg.getBBox();
          const { x, y, width: bboxWidth, height: bboxHeight } = bbox;

          const cx = x + bboxWidth / 2;
          const cy = y + bboxHeight / 2;
          docSvg.remove();
          const splittedText = (f.properties["system-style"]?.text || '').split('\n');
          const [r, g, b] = HSLToRGB(f.properties["system-style"]?.color || 'transparent');
          const fontColor = ((r * 0.299 + g * 0.587 + b * 0.114) > 186) ? 'black' : 'white';

          return `<text
            x="${cx}"
            y="${cy + (1 - splittedText.length) * 5}"
            dominant-baseline="middle"
            text-anchor="middle"
            font-size="8px"
            font-family="sans-serif"
            fill="${fontColor}"
            stroke="${fontColor}"
            stroke-width="0.2px"
            stroke-linejoin="round"
          >
            ${splittedText.map((s, i) => `<tspan x="${cx}" ${i > 0 ? 'dy="1.2em"' : ''}>${s}</tspan>`).join('')}
         </text>`;
        }
        return '';
      }
    )

    const svg = `<svg 
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 ${width} ${height}" 
      version="1.2" 
      stroke-linecap="round" 
      stroke-linejoin="round" 
      width="${width}" 
      height="${height}"
    >
    ${geojson.features.reduce(
      (r, f) =>
        r +
        `<path
          d="${generator(f)}"
          data-id="${f.properties._id}"
          data-name="${f.properties.name}"
          fill="${f.properties["system-style"]?.color || 'transparent'}"
          stroke="${f.properties["system-style"]?.color || 'black'}"
          fill-opacity="${f.properties["system-style"]?.opacity || '1'}"
        />\n\t\t`,
      ""
    )}
    ${texts}
  </svg>`;
    return svg;
  }
  return false;
}

export async function geojson2Img(geojson: RGFeatureCollection, mimeType: string) {
  if (geojson) {
    const svgStr = geojson2SVG(geojson);
    if (svgStr) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = 500;
      canvas.height = 500;
      const image = new Image;
      image.src = 'data:image/svg+xml;base64,' + btoa(svgStr);
      await image.decode();
      if (context) {
        context.fillStyle = context.createPattern(image, 'repeat') || '';
        context.fillRect(0, 0, canvas.width, canvas.height);
        return canvas.toDataURL(mimeType);
      }
      return null;
    }
  }
  return null;
}

export async function copyTextToClipboard(text: string): Promise<boolean> {
  return new Promise((resolve) => {
    if (!navigator.clipboard) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        resolve(successful);
      } catch (err) {
        resolve(false);
      }

      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard
      .writeText(text)
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
}

export function formatPropertiesIds(geojson: RGFeatureCollection) {
  return geojson = {
    ...geojson,
    features: geojson.features.map((feature) => {
      const { properties: { id, _id, ...rest } } = feature;
      return {
        ...feature,
        properties: {
          ...rest,
          _id: _id || id
        }
      }
    })
  }
}
